import React, { Component } from "react";
import { TableView } from "../components";
import { withStyles } from "@material-ui/core/styles";
import { db, fb } from "../firebase";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import moment from "moment";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary
	},
	media: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: "cover"
	}
});

class AllOffers extends Component {
	state = {
		storeName: "",
		modalOpen: false,
		loading: true,
		target: "",
		currencyListString: "-",
		allStores: [],
		expanded: null,
		allOffers: {},
		allCustomers: {},
		admin: false,
		conversions: 0,
		transactionAmount: 0,
		customerCount: 0,
		recurringCustomers: 0
	};

	snapshotToArray(snapshot) {
		var returnArr = [];

		snapshot.forEach(function(childSnapshot) {
			var item = childSnapshot.val();
			item.key = childSnapshot.key;

			returnArr.push(item);
		});
		return returnArr;
	}

	componentDidMount() {
		// let alloffers = {};
		// let allcustomers = {};
		// let conversions = 0;
		// let transactionamount = 0;
		// let customercount = 0;
		// let customernooffercount = 0;
		// let recustcount = 0;
		db.getAllStoreNames().then(snapshot => {
			this.setState({
				allStores: this.snapshotToArray(snapshot)
			});
		});
	}

	handleOpen = target => {
		this.setState({
			modalOpen: true,
			target: target
		});
	};
	handleClose = () => {
		this.setState({
			modalOpen: false,
			target: ""
		});
	};
	handleChangePanel = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false
		});
	};
	// handlePendingCallback = (val) => {
	// 	console.log('here', val)
	// };

	render() {
		const { classes } = this.props;
		const { expanded, admin, allStores } = this.state;

		var storeList = [];
		for (var i = 0; i < allStores.length; i++) {
			if (allStores[i].storeName) {
				storeList.push(allStores[i]);
			}
		}
		function removeDuplicates(myArr, prop) {
			return myArr.filter((obj, pos, arr) => {
				return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
			});
		}
		storeList = removeDuplicates(storeList, "storeName");
		// storeList.sort((a, b) =>
		// 	moment(a.isOnline).isBefore(b.isOnline) ? 1 : -1
		// );
		storeList.sort(function(a, b) {
			return String(a.storeName).localeCompare(b.storeName);
		});

		return (
			<div>
				<div>
					<br></br>
					{storeList.map((value, index) => {
						var time = moment(storeList[index].isOnline).fromNow();
						if (storeList[index].isOnline === true) time = "a few seconds ago";
						return (
							<div key={index}>
								<ExpansionPanel key={index} expanded={expanded === index} onChange={this.handleChangePanel(index)}>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
										<Typography
											style={{
												fontSize: "2em",
												fontWeight: "700",
												color: "#35B2FF",
												marginRight: "2em"
											}}>
											{_.startCase(storeList[index].storeName)}
										</Typography>
										{time === "a few seconds ago" ? (
											<Button
												variant='outlined'
												style={{
													color: "#0f0",
													border: "1px solid #0f0",
													padding: "0em 0.5em"
												}}>
												Online
											</Button>
										) : (
											<Button
												variant='outlined'
												style={{
													color: "#f00",
													border: "1px solid #f00",
													padding: "0em 0.5em"
												}}>
												{time}
											</Button>
										)}
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<TableView
											storeName={storeList[index].storeName}
											admin={true}
											// pendingCallback = {this.handlePendingCallback}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>

								<br />
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(AllOffers);
