import firebase from "firebase/app";
import "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import firebaseDate from "firebase";

var adminConfig = {
	apiKey: "AIzaSyDgDTelKrHQ8Qqpi6lzAF9SLAbzFPDxkno",
	authDomain: "oaklas-admin.firebaseapp.com",
	databaseURL: "https://oaklas-admin.firebaseio.com",
	projectId: "oaklas-admin",
	storageBucket: "oaklas-admin.appspot.com",
	messagingSenderId: "918002437373",
};

let usersDBConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-users-e5979.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

let rulesConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-bookmark-tool-991c2.firebaseio.com/",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

const retailerConfig = {
	apiKey: "AIzaSyCgiHK5cVxarmTZyPnYPgAooq7f8FQVhM0",
	authDomain: "oaklas-bookmark-tool.firebaseapp.com",
	databaseURL: "https://oaklas-bookmark-tool.firebaseio.com",
	projectId: "oaklas-bookmark-tool",
	storageBucket: "oaklas-bookmark-tool.appspot.com",
	messagingSenderId: "650641977232",
};

const customerConfig = {
	apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
	authDomain: "oaklas-user.firebaseapp.com",
	databaseURL: "https://oaklas-user.firebaseio.com",
	projectId: "oaklas-user",
	storageBucket: "oaklas-user.appspot.com",
	messagingSenderId: "282663992239",
};

const websiteConfig = {
	apiKey: "AIzaSyAzQSf91bn5EfHcd3fCTrDItYhBu7l6LLg",
	authDomain: "oaklas-website.firebaseapp.com",
	databaseURL: "https://oaklas-website.firebaseio.com",
	projectId: "oaklas-website",
	storageBucket: "oaklas-website.appspot.com",
	messagingSenderId: "773320995457",
	appId: "1:773320995457:web:e0ab05609f96a0ffd21380",
	measurementId: "G-3MMB3EZF4Z",
};
const marketConfig = {
	apiKey: "AIzaSyB5rSaDR8wATQh7XcppVYpv5A3cILnwjNo",
	authDomain: "oaklas-user.firebaseapp.com",
	databaseURL: "https://enteroffer-marketplace.firebaseio.com",
	projectId: "oaklas-user",
	storageBucket: "oaklas-user.appspot.com",
	messagingSenderId: "282663992239",
};

const shouldInit = !firebase.apps.length;

const retailerfb = shouldInit ? firebase.initializeApp(retailerConfig) : firebase.apps[0];
const adminfb = shouldInit ? firebase.initializeApp(adminConfig, "admin") : firebase.apps[1];
const userfb = shouldInit ? firebase.initializeApp(usersDBConfig, "users") : firebase.apps[2];
const rulesfb = shouldInit ? firebase.initializeApp(rulesConfig, "rules") : firebase.apps[3];
const customerfb = shouldInit ? firebase.initializeApp(customerConfig, "customers") : firebase.apps[4];
const websitefb = shouldInit ? firebase.initializeApp(websiteConfig, "website") : firebase.apps[5];
const marketfb = shouldInit ? firebase.initializeApp(marketConfig, "market") : firebase.apps[6];

const retailerdb = retailerfb.database();
const userdb = userfb.database();
const rulesdb = rulesfb.database();
const retailerStorage = retailerfb.storage();
const customerdb = customerfb.database();
const marketdb = marketfb.database();
const websitedb = websitefb.database();

const auth = retailerfb.auth();
const functions = customerfb.functions();
const adminFunctions = adminfb.functions();

if (shouldInit && process.env.REACT_APP_LOCAL_TESTING == "true") {
	console.log("*****LOCAL MODE - Production data not in use*****");
	retailerdb.useEmulator("localhost", 9000);
	userdb.useEmulator("localhost", 9000);
	rulesdb.useEmulator("localhost", 9000);
	customerdb.useEmulator("localhost", 9000);
	marketdb.useEmulator("localhost", 9000);
	websitedb.useEmulator("localhost", 9000);

	retailerStorage.useEmulator("localhost", 9199);

	auth.useEmulator("http://localhost:9099");

	functions.useFunctionsEmulator("http://localhost:5001");
	adminFunctions.useFunctionsEmulator('http://localhost:5001')

}

export {
	firebase,
	auth,
	adminfb,
	retailerfb,
	retailerdb,
	userfb,
	userdb,
	rulesfb,
	rulesdb,
	customerdb,
	retailerStorage,
	firebaseDate,
	functions,
	adminFunctions,
	websitedb,
	marketdb,
};
