import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { db, fb } from "../firebase";
import { MenuItem, TextField, Paper, Grid, CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import _ from "lodash";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "left",
		color: theme.palette.text.secondary,
	},
	media: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: "cover",
	},
	progress: {
		margin: theme.spacing(1),
		marginTop: "30vh",
	},
});

class CustomerData extends Component {
	state = {
		storeName: "",
		modalOpen: false,
		loading: true,
		target: "",
		currencyListString: "-",
		allStores: [],
		expanded: null,
		allOffers: {},
		allCustomers: {},
		admin: false,
		transactionAmount: 0,
		customerCount: 0,
		recurringCustomers: 0,
		recCustList: [],
		singCustList: [],
		approvalAmount: 0,
		conversions: 0,
		customernooffercount: 0,
		queryString: "",
		fromTime: moment().subtract(5, "years").format(),
		toTime: moment().format(),
		signUps: {},
		addresses: {},
		cards: {},
	};
	handleChange = (name) => (event) => {
		if (name === "fromTime" || name === "toTime") {
			let date = moment(event.target.value).format();
			this.setState({ [name]: date });
		} else {
			this.setState({ [name]: event.target.value });
		}
	};

	snapshotToArray(snapshot) {
		var returnArr = [];

		snapshot.forEach(function (childSnapshot) {
			var item = childSnapshot.val();
			item.key = childSnapshot.key;

			returnArr.push(item);
		});
		return returnArr;
	}

	componentDidMount() {
		let alloffers = {};
		let allcustomers = {};
		let stores = [];

		fb.getAllBids()
			.then((allstoreval) => {
				allstoreval = allstoreval.data;
				alloffers = allstoreval;
				_.map(alloffers, (val, key) => {
					if (key !== "productsRUs" && key !== "bestFruits" && key !== "undefined" && key !== "approved" && key !== "apparelForYou") {
						let obj = {};
						obj["value"] = key;
						obj["label"] = _.startCase(key);
						stores.push(obj);
					}
				});
				return fb.getAllCustomers();
			})
			.then((allcusts) => {
				allcusts = allcusts.data;
				allcustomers = allcusts;

				this.setState({
					loading: false,
					allOffers: alloffers,
					allStores: stores,
					allCustomers: allcustomers,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	}

	handleOpen = (target) => {
		this.setState({
			modalOpen: true,
			target: target,
		});
	};
	handleClose = () => {
		this.setState({
			modalOpen: false,
			target: "",
		});
	};
	handleChangePanel = (panel) => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false,
		});
	};

	getOfferStats = () => {
		const { queryString, allOffers, fromTime, toTime } = this.state;
		let allstoreval =
			queryString === ""
				? allOffers
				: _.pickBy(allOffers, (value, key) => {
						return key.toLowerCase().includes(queryString.toLowerCase());
				  });
		allstoreval = _.omitBy(allstoreval, (value, key) => {
			return key === "productsRUs" || key === "bestFruits" || key === "undefined" || key === "approved" || key === "apparelForYou";
		});
		for (let store in allstoreval) {
			allstoreval[store] = _.pickBy(allstoreval[store], (value, key) => {
				return key === "approved" || key === "rejected" || key === "pending";
			});
		}
		let fromTimeStamp = moment(fromTime);
		let toTimeStamp = moment(toTime);

		//filtering result by these
		for (let store in allstoreval) {
			for (let section in allstoreval[store]) {
				allstoreval[store][section] = _.pickBy(allstoreval[store][section], (value, key) => {
					let checkDate = moment(value.submittedDate);
					return moment(checkDate).isSameOrAfter(fromTimeStamp) && moment(checkDate).isSameOrBefore(toTimeStamp);
				});
			}
		}

		let conversions = 0;
		let approvalamount = 0;
		let transactionamount = 0;
		let averdict = 0;
		let averaccptdict = 0;
		let transactedcount = 0;
		let totalCount = 0;
		for (let store in allstoreval) {
			for (let section in allstoreval[store]) {
				conversions += Object.keys(allstoreval[store][section]).length;
				if (section === "approved") {
					for (let key in allstoreval[store][section]) {
						approvalamount++;
						if (allstoreval[store][section][key].payment && allstoreval[store][section][key].order) {
							transactionamount += Number(allstoreval[store][section][key].bidPrice);
							averaccptdict += Number(allstoreval[store][section][key].listPrice - allstoreval[store][section][key].bidPrice);
							transactedcount++;
						}
					}
				}
				for (let key in allstoreval[store][section]) {
					if (!isNaN(Number(allstoreval[store][section][key].listPrice)) && !isNaN(Number(allstoreval[store][section][key].bidPrice))) {
						totalCount++;
						averdict += Number(allstoreval[store][section][key].listPrice) - Number(allstoreval[store][section][key].bidPrice);
					}
				}
			}
		}
		if (totalCount === 0) {
			averdict = 0;
		} else {
			averdict = averdict / totalCount;
		}
		if (transactedcount === 0) {
			averaccptdict = 0;
		} else {
			averaccptdict = averaccptdict / transactedcount;
		}
		return {
			conversions: conversions,
			transactionAmount: transactionamount,
			approvalAmount: approvalamount,
			averageDiscount: averdict,
			averageAcceptedDiscount: averaccptdict,
		};
	};

	getCustomerStats = () => {
		const { queryString, allCustomers } = this.state;

		let allcusts =
			queryString === ""
				? allCustomers
				: _.pickBy(allCustomers, (value, key) => {
						if (value.meta !== undefined && value.meta !== null) {
							if (value.meta.whichStore !== undefined && value.meta.whichStore !== null) {
								if (value.meta.whichStore.toLowerCase().includes(queryString.toLowerCase())) {
									return true;
								}
							}
						}
						if (value.stores !== undefined && value.stores !== null) {
							let storeNamesArr = Object.keys(value.stores);
							for (let storeName in storeNamesArr) {
								if (storeName.toLowerCase().includes(queryString.toLowerCase())) {
									return true;
								}
							}
						}
						return false;
				  });

		let recustcount = 0;
		let customercount = 0;
		let recCustList = [];
		let singCustList = [];
		let realcusts = _.pickBy(allcusts, (value, key) => {
			return value.stores;
		});
		let realSignups = _.pickBy(allcusts, (value, key) => {
			return value.meta;
		});
		let arrSignUps = {};
		let arrAddress = {};
		let arrCards = {};
		let arrEmails = {};
		for (let uid in realSignups) {
			realSignups[uid].stores = _.omitBy(realSignups[uid].stores, (value, key) => {
				return key === "productsRUs" || key === "bestFruits" || key === "apparelForYou";
			});
			realSignups[uid].meta = _.omitBy(realSignups[uid].meta, (value, key) => {
				return value.whichStore === "productsRUs" || value.whichStore === "bestFruits" || value.whichStore === "apparelForYou";
			});
			if (
				realSignups[uid].meta.whichStore !== "productsRUs" &&
				realSignups[uid].meta.whichStore !== "bestFruits" &&
				realSignups[uid].meta.whichStore !== "apparelForYou"
			) {
				var month = moment(realSignups[uid].meta.signUpDate).month();
				if (
					realSignups[uid].meta.whichStore !== undefined &&
					realSignups[uid].meta.signUpDate !== undefined &&
					moment().diff(moment(realSignups[uid].meta.signUpDate), "months") < 6
				) {
					if (arrSignUps[month] === undefined) arrSignUps[month] = 0;
					arrSignUps[month] = arrSignUps[month] + 1;

					if (realSignups[uid].meta.billingAddress !== undefined) {
						if (arrAddress[month] === undefined) arrAddress[month] = 0;
						arrAddress[month] = arrAddress[month] + 1;
					}
					if (realSignups[uid].meta.payment !== undefined) {
						if (arrCards[month] === undefined) arrCards[month] = 0;
						arrCards[month] = arrCards[month] + 1;
					}
					// if (
					// 	realSignups[uid].meta.billingAddress === undefined &&
					// 	realSignups[uid].meta.payment !== undefined
					// ) {
					// 	console.log(
					// 		realSignups[uid],
					// 		realSignups[uid].meta.whichStore,
					// 		moment(realSignups[uid].meta.signUpDate).format()
					// 	);
					// }

					if (realSignups[uid].meta.email !== undefined) {
						if (arrEmails[month] === undefined) arrEmails[month] = 0;
						arrEmails[month] = arrEmails[month] + 1;
					}
				}
			}
		}

		for (let uid in realcusts) {
			realcusts[uid].stores = _.omitBy(realcusts[uid].stores, (value, key) => {
				return key === "productsRUs" || key === "bestFruits" || key === "apparelForYou";
			});
			realcusts[uid] = _.omitBy(realcusts[uid], (value, key) => {
				return realcusts[uid]["meta"] === undefined;
			});
			realcusts[uid] = _.omitBy(realcusts[uid], (value, key) => {
				return _.has(realcusts[uid]["meta"]["anonymous"]) && realcusts[uid]["meta"]["anonymous"] === true;
			});
		}

		for (let uid in realcusts) {
			let userOfferCount = 0;
			let userApprovedCount = 0;
			if (realcusts[uid].stores) {
				for (let store in realcusts[uid].stores) {
					realcusts[uid].stores[store] = _.pickBy(realcusts[uid].stores[store], (value, key) => {
						return key === "approved" || key === "rejected" || key === "pending";
					});

					for (let section in realcusts[uid].stores[store]) {
						for (let entryKey in realcusts[uid].stores[store][section]) {
							userOfferCount++;

							if (realcusts[uid].stores[store][section][entryKey].approved === true) {
								realcusts[uid].payment = realcusts[uid].stores[store][section][entryKey].payment;
								userApprovedCount++;
							}
						}
					}
				}
			}
			realcusts[uid].approvedCount = userApprovedCount;

			if (userOfferCount === 1) {
				singCustList.push(realcusts[uid]);
				customercount++;
			}
			if (userOfferCount >= 2) {
				recCustList.push(realcusts[uid]);
				recustcount++;
			}
		}

		return {
			customerCount: customercount,
			recurringCustomers: recustcount,
			singCustList: singCustList,
			recCustList: recCustList,
			signUps: arrSignUps,
			addresses: arrAddress,
			cards: arrCards,
			emails: arrEmails,
		};
	};

	render() {
		const { classes } = this.props;
		const { allStores } = this.state;
		const loading = this.state.loading;
		const offerStats = this.getOfferStats();
		const customerStats = this.getCustomerStats();
		// const conversions = offerStats.conversions;
		const customerCount = customerStats.customerCount;
		const recurringCustomers = customerStats.recurringCustomers;
		var recurringCustomersList = customerStats.recCustList;
		const singleCustomersList = customerStats.singCustList;
		const signUpDates = customerStats.signUps;
		const signUpAddress = customerStats.addresses;
		const signUpCards = customerStats.cards;
		const signUpEmails = customerStats.emails;
		recurringCustomersList = recurringCustomersList.sort((a, b) => (a.approvedCount > b.approvedCount ? -1 : 1));

		const chartData = {
			labels: [
				moment().subtract(5, "months").format("MMM"),
				moment().subtract(4, "months").format("MMM"),
				moment().subtract(3, "months").format("MMM"),
				moment().subtract(2, "months").format("MMM"),
				moment().subtract(1, "months").format("MMM"),
				moment().format("MMM"),
			],
			datasets: [
				{
					label: "Sign Up",
					data: [
						signUpDates[moment().subtract(5, "months").month()],
						signUpDates[moment().subtract(4, "months").month()],
						signUpDates[moment().subtract(3, "months").month()],
						signUpDates[moment().subtract(2, "months").month()],
						signUpDates[moment().subtract(1, "months").month()],
						signUpDates[moment().month()],
					],
					borderWidth: 1,
					borderColor: "rgba(42, 228, 155, 1)",
					backgroundColor: "rgba(42, 228, 155, .13)",
					fill: false,
				},
				{
					label: "Email Entered",
					data: [
						signUpEmails[moment().subtract(5, "months").month()],
						signUpEmails[moment().subtract(4, "months").month()],
						signUpEmails[moment().subtract(3, "months").month()],
						signUpEmails[moment().subtract(2, "months").month()],
						signUpEmails[moment().subtract(1, "months").month()],
						signUpEmails[moment().month()],
					],
					borderWidth: 1,
					borderColor: "rgba(155, 127, 88, 1)",
					backgroundColor: "rgba(155, 127, 88, .14)",
					fill: false,
				},
				{
					label: "Address Entered",
					data: [
						signUpAddress[moment().subtract(5, "months").month()],
						signUpAddress[moment().subtract(4, "months").month()],
						signUpAddress[moment().subtract(3, "months").month()],
						signUpAddress[moment().subtract(2, "months").month()],
						signUpAddress[moment().subtract(1, "months").month()],
						signUpAddress[moment().month()],
					],
					borderWidth: 1,
					borderColor: "rgba(255, 77, 77, 1)",
					backgroundColor: "rgba(255, 77, 77, .14)",
					fill: false,
				},
				{
					label: "Payment Entered",
					data: [
						signUpCards[moment().subtract(5, "months").month()],
						signUpCards[moment().subtract(4, "months").month()],
						signUpCards[moment().subtract(3, "months").month()],
						signUpCards[moment().subtract(2, "months").month()],
						signUpCards[moment().subtract(1, "months").month()],
						signUpCards[moment().month()],
					],
					borderWidth: 1,
					borderColor: "rgba(255, 227, 38, 1)",
					backgroundColor: "rgba(255, 227, 38, .14)",
					fill: false,
				},
			],
		};

		return (
			<div className={classes.root}>
				{loading ? (
					<div style={{ textAlign: "center" }}>
						<CircularProgress size={100} className={classes.progress} />
					</div>
				) : (
					<Grid container spacing={1}>
						<Grid item xs={3}>
							<TextField
								select
								label="All Stores"
								className={classes.textField}
								value={this.state.queryString}
								onChange={this.handleChange("queryString")}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								helperText="Please select your store"
								margin="normal"
							>
								{allStores.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4} style={{ padding: "32px 16px" }}>
							<TextField
								id="date"
								label="From Time"
								type="date"
								onChange={this.handleChange("fromTime")}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={4} style={{ padding: "32px" }}>
							<TextField
								id="date"
								label="To Time"
								type="date"
								onChange={this.handleChange("toTime")}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<p>
									<b>Recurring Customer List (Count : {recurringCustomers})</b>
								</p>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<p>
									<b>Single Use Customer List (Count : {customerCount})</b>
								</p>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<Grid container>
									<Grid item xs={3}>
										<p>
											<b>Approved</b>
										</p>
									</Grid>
									<Grid item xs={3}>
										<p>
											<b>Name</b>
										</p>
									</Grid>
									<Grid item xs={6}>
										<p>
											<b>Email</b>
										</p>
									</Grid>
									{_.map(recurringCustomersList, (x, cnt) => {
										return (
											<Grid item container xs={12} key={cnt}>
												<Grid item xs={3}>
													<p>{x.approvedCount !== 0 ? x.approvedCount : 0}</p>
												</Grid>
												<Grid item xs={3}>
													<p>
														{x.meta.firstName ? x.meta.firstName : "-"} {x.meta.lastName ? x.meta.lastName : "-"}
													</p>
												</Grid>
												<Grid item xs={6}>
													<p key={cnt}>{x.meta.email ? x.meta.email : "-"}</p>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<Grid container>
									<Grid item xs={2}>
										<p>
											<b>Status</b>
										</p>
									</Grid>
									<Grid item xs={3}>
										<p>
											<b>Name</b>
										</p>
									</Grid>
									<Grid item xs={5}>
										<p>
											<b>Email</b>
										</p>
									</Grid>
									<Grid item xs={2}>
										<p>
											<b>Payment</b>
										</p>
									</Grid>
									{_.map(singleCustomersList, (x, cnt) => {
										return (
											<Grid item container xs={12} key={cnt}>
												<Grid item xs={2}>
													<p>{x.approvedCount === 1 ? "Approved" : "Rejected"} </p>
												</Grid>
												<Grid item xs={3}>
													<p>
														{x.meta.firstName ? x.meta.firstName : "-"} {x.meta.lastName ? x.meta.lastName : "-"}{" "}
													</p>
												</Grid>
												<Grid item xs={5}>
													<p>{x.meta.email ? x.meta.email : "-"} </p>
												</Grid>
												<Grid item xs={2}>
													<p>{x.payment && x.approvedCount === 1 ? "Done" : !x.payment && x.approvedCount === 1 ? "Failed" : "-"}</p>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<Bar
									data={chartData}
									options={{
										scales: {
											yAxes: [
												{
													ticks: {
														min: 0,
														callback: function (value) {
															if (value % 1 === 0) {
																return value;
															}
														},
													},
												},
											],
										},
									}}
								/>
							</Paper>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(CustomerData);
