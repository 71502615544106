import React from "react";
import PropTypes from "prop-types";
import { db } from "../firebase";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import _ from "lodash";
import Tick from "@material-ui/icons/Done";
import Cross from "@material-ui/icons/Clear";

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: "storeName", numeric: false, disablePadding: true, label: "Store Name" },
	{ id: "fullName", numeric: true, disablePadding: false, label: "Name" },
	{ id: "url", numeric: true, disablePadding: false, label: "Url" },
	{ id: "email", numeric: true, disablePadding: false, label: "Email" },
	{ id: "myShopifyUrl", numeric: true, disablePadding: false, label: "myShopify Url" },
	{ id: "stripe", numeric: false, disablePadding: false, label: "Stripe" },
	{ id: "shopify", numeric: false, disablePadding: false, label: "Shopify" }
];

class OnboardingTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map(
						row => (
							<TableCell
								key={row.id}
								align={"left"}
								padding={"default"}
								sortDirection={orderBy === row.id ? order : false}>
								<Tooltip
									title='Sort'
									placement={row.numeric ? "bottom-end" : "bottom-start"}
									enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						),
						this
					)}

					<TableCell padding='checkbox'>
						<Checkbox
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={numSelected === rowCount}
							onChange={onSelectAllClick}
						/>
					</TableCell>
				</TableRow>
			</TableHead>
		);
	}
}

OnboardingTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	spacer: {
		flex: "1 1 100%"
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: "0 0 auto"
	}
});

let OnboardingTableToolbar = props => {
	const { numSelected, classes, selected, singleSelect, feDelete } = props;

	return (
		<Toolbar>
			<div className={classes.title}>
				{numSelected > 0 ? (
					<Typography color='inherit' variant='subtitle1'>
						{numSelected} selected
					</Typography>
				) : (
					<Typography variant='h6' id='tableTitle'>
						Nutrition
					</Typography>
				)}
			</div>
			<div className={classes.spacer} />
			<div className={classes.actions}>
				{numSelected === 1 ? (
					<Tooltip title='Accept'>
						<IconButton
							aria-label='Accept'
							onClick={() => {
								db.completeSignUp(singleSelect, selected[0]);
								db.deleteBadSignup(selected[0]);
								feDelete(selected[0]);
							}}>
							<CheckCircleIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</div>
			<div className={classes.actions}>
				{numSelected > 0 ? (
					<Tooltip title='Delete'>
						<IconButton
							aria-label='Delete'
							onClick={() => {
								for (let i in selected) {
									db.deleteBadSignup(selected[i]);
									feDelete(selected);
								}
							}}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</div>
		</Toolbar>
	);
};

OnboardingTableToolbar = withStyles(toolbarStyles)(OnboardingTableToolbar);

const styles = theme => ({
	root: {
		width: "100%",
		marginTop: theme.spacing(1) * 3
	},
	table: {
		minWidth: 1020
	},
	tableWrapper: {
		overflowX: "auto"
	}
});

class OnboardingTable extends React.Component {
	state = {
		order: "asc",
		orderBy: "fullName",
		selected: [],
		singleSelect: {},
		data: [],
		page: 0,
		rowsPerPage: 10,
		loading: true
	};

	componentDidMount() {
		db.getNewStores().then(newStores => {
			let dataArray = [];
			_.map(newStores, (n, id) => {
				let storeObj = n.meta;
				storeObj.id = id;
				dataArray.push(storeObj);
			});
			this.setState({ data: dataArray, loading: false });
		});
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		this.setState({ order, orderBy });
	};

	handleSelectAllClick = event => {
		if (event.target.checked) {
			this.setState(state => ({ selected: state.data.map(n => n.id) }));
			return;
		}
		this.setState({ selected: [] });
	};

	handleClick = (event, obj) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(obj.id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, obj.id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		this.setState({ selected: newSelected, singleSelect: obj });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	handleFEDelete = selected => {
		const { data } = this.state;
		let newData = [];
		newData = data.filter(function(obj) {
			return !selected.includes(obj.id);
		});
		this.setState({ data: newData, selected: [] });
	};

	render() {
		const { classes } = this.props;
		const { data, order, orderBy, selected, singleSelect, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

		return (
			<Paper className={classes.root}>
				<OnboardingTableToolbar
					numSelected={selected.length}
					selected={selected}
					singleSelect={singleSelect}
					feDelete={this.handleFEDelete}
				/>
				<div className={classes.tableWrapper}>
					<Table className={classes.table}>
						<OnboardingTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={this.handleSelectAllClick}
							onRequestSort={this.handleRequestSort}
							rowCount={data.length}
						/>
						<TableBody>
							{stableSort(data, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map(n => {
									const isSelected = this.isSelected(n.id);
									return (
										<TableRow
											hover
											key={n.id}
											onClick={event => this.handleClick(event, n)}
											role='checkbox'
											aria-checked={isSelected}
											tabIndex={-1}
											key={n.id}
											selected={isSelected}>
											<TableCell component='th' scope='row'>
												{n.storeName}
											</TableCell>
											<TableCell align='left'>
												{n.firstName} {n.lastName}
											</TableCell>
											<TableCell align='left'>{n.url}</TableCell>
											<TableCell align='left'>{n.email}</TableCell>
											<TableCell align='left'>{n.shopName}</TableCell>
											<TableCell align='left'>{n.stripe ? <Tick /> : <Cross />}</TableCell>
											<TableCell align='left'>
												{n.shopifySuccess ? <Tick /> : <Cross />}
											</TableCell>
											<TableCell padding='checkbox'>
												<Checkbox checked={isSelected} />
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component='div'
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						"aria-label": "Previous Page"
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page"
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
			</Paper>
		);
	}
}

OnboardingTable.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OnboardingTable);
