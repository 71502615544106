import { auth, db, adminFunctions, userdb, retailerdb, customerdb, rulesdb, marketdb, websitedb } from "./firebase";
import _ from "lodash";
import moment from "moment";
// adminFunctions.useFunctionsEmulator('http://localhost:5000')

export function getCurrentUser() {
	return new Promise(function(resolve, reject) {
		return resolve(auth.currentUser);
	});
}

export function getUserID() {
	return auth.currentUser.uid;
}

export function getAllStoreNames() {
	return new Promise(function(resolve, reject) {
		userdb
			.ref("/")
			.once("value")
			.then(function(snap) {
				return resolve(snap);
			});
	});
}

export function getStoreBids(storeName, whichType) {
	return new Promise(function(resolve, reject) {
		retailerdb
			.ref("stores/" + storeName + `/${whichType}`)
			.once("value", (snapshot) => {
				return resolve(snapshot);
			})
			.catch((e) => {
				console.log(e);
			});
	});
}

export function getUserStats() {
	return new Promise(function(resolve, reject) {
		// const date = new Date()
		var getUserStats = adminFunctions.httpsCallable("getUserStatsFinal");
		getUserStats().then((result) => {
			console.log(result);
			// Read result of the Cloud Function.
			return resolve(result.data);
			//var sanitizedMessage = result.data.names;
			//console.log(sanitizedMessage)
		});
	});
}

export function getUserMetaInfo() {
	return new Promise(function(resolve, reject) {
		var getUserMetaInfo = adminFunctions.httpsCallable("getUserMetaInfo");
		var arr = new Array();
		getUserMetaInfo().then((result) => {
			console.log(result);
			for (let user in result.data) {
				console.log(result.data[user].meta);
				if (result.data[user].meta === undefined || result.data[user].meta === null) continue;
				var obj = {
					uid: user,
					email: result.data[user].meta.email !== null && result.data[user].meta.email !== undefined ? result.data[user].meta.email : "",
					recentOfferUrl: result.data[user].meta.recentOfferUrl,
					signUpDate: result.data[user].meta.signUpDate,
					whichStore: result.data[user].meta.whichStore,
					offer: result.data[user].hasOwnProperty("stores") ? "true" : "false",
				};
				arr.push(obj);
			}
			return resolve(arr);
		});
	});
}

export function getAllBids() {
	console.log(auth.currentUser);
	return new Promise(function(resolve, reject) {
		retailerdb
			.ref("/stores")
			.once("value", (snapshot) => {
				return resolve(snapshot);
			})
			.catch((e) => {
				console.log(e);
			});
	});
}

export function getAllCustomers() {
	return new Promise(function(resolve, reject) {
		customerdb
			.ref("/")
			.once("value", (snapshot) => {
				return resolve(snapshot);
			})
			.catch((e) => {
				console.log(e);
			});
	});
}

export function fakePromise() {
	return new Promise(function(resolve, reject) {
		return resolve(true);
	});
}

export function getAllRules() {
	return new Promise(function(resolve, reject) {
		rulesdb
			.ref("/")
			.once("value", (snapshot) => {
				return resolve(snapshot);
			})
			.catch((e) => {
				console.log(e);
			});
	});
}

export function getNewStores() {
	return new Promise(function(resolve, reject) {
		retailerdb
			.ref("/newStores")
			.once("value", (snapshot) => {
				return resolve(snapshot.val());
			})
			.catch((e) => {
				console.error(e);
				return reject(e);
			});
	});
}

export function deleteBadSignup(id) {
	retailerdb.ref(`/newStores/${id}`).remove();
}

export function completeSignUp(meta, id) {
	let userID = {};
	userID[id] = true;
	let shopify = {};
	shopify["accessToken"] = meta.accessToken;
	shopify["shopName"] = meta.shopName;

	let button = { color: "#000000", textColor: "#FFFFFF" };
	let theme = { color: "000000" };
	let pushObj = {
		abn: meta.abn,
		email: meta.email,
		firstName: meta.firstName,
		lastName: meta.lastName,
		storeName: meta.storeName,
		url: meta.url,
		shopify: shopify,
		users: userID,
		button: button,
		theme: theme,
		type: "shopify",
		feePerc: 2,
	};
	let pushName = _.camelCase(meta.storeName);

	let stripeObj = { stripeUserId: meta.stripe };

	retailerdb.ref(`/stores/${pushName}/meta`).set(pushObj);
	retailerdb.ref(`/stores/${pushName}/stripe`).set(stripeObj);
}

export function getAllProducts() {
	return new Promise((resolve, reject) => {
		marketdb
			.ref("/products/")
			.once("value", (snapshot) => {
				return resolve(snapshot.val());
			})
			.catch((e) => {
				return reject(e);
			});
	});
}
export function addProduct(prod) {
	console.log(prod);
	marketdb
		.ref(`/products/${prod.id}`)
		.set(prod)
		.then((x) => {
			return;
		})
		.catch((e) => {
			console.error(e);
		});
}

export function makeTrending(pid, flag) {
	let id = String(pid).toLowerCase();
	marketdb
		.ref(`/products/${id}/trending/`)
		.set(flag)
		.then((x) => {
			return;
		})
		.catch((e) => {
			console.error(e);
		});
}

export function updateListing(type, id, url, prodId) {
	return new Promise((resolve, reject) => {
		websitedb
			.ref(`marketplace/${type}/${id}`)
			.update({
				"listed": url,
			})
			.then(() => {
				websitedb
					.ref(`marketplace/${type}/${id}/offer`)
					.update({
						"listed": true,
						"sold": false,
						"productId": prodId
					}).then(() => {
						console.log("Product updated")
						return resolve();
					})
					.catch((e) => {
						console.error(e)
						return reject(e);
					});
			})
			.catch((e) => {
				console.error(e)
				return reject(e);
			});
	});
}

function filter(snap, f) {
	var ret = [];
	if (f) {
		for (let key in snap) {
			if(!snap[key].offer) continue;
			let obj = { offer: snap[key].offer, sellId: key };
			if (snap[key].offer.listed === undefined || snap[key].offer.listed === null) obj.offer.listed = false;
			if (!_.isEmpty(snap[key].offer.productUrl)) obj.offer.listed = snap[key].offer.productUrl;
			ret.push(obj);
		}
	} else {
		for (let key in snap) {
			let obj = { offer: snap[key], buyId: key };
			if (snap[key].listed === undefined || snap[key].listed === null) obj.offer.listed = false;
			ret.push(obj);
		}
	}
	ret.sort((a, b) => (moment(a.submittedDate).isSameOrAfter(moment(b.submittedDate)) ? -1 : 1));
	return ret;
}

export function getListings() {
	return new Promise(function(resolve, reject) {
		websitedb
			.ref("marketplace")
			.once(
				"value",
				(snap) => {
					var obj = snap.val();
					if(!obj) return resolve({ buy: [], sell: [] });
					let sell = filter(obj.sell, true);
					let buy = filter(obj.buy, false);
					let ret = { buy: buy, sell: sell };
					return resolve(ret);
				},
				(error) => {
					reject(error);
				}
			);
	});
}

export function deleteListing(type, id) {
	return new Promise(function(resolve, reject) {
		websitedb
			.ref(`marketplace/${type}/${id}`)
			.remove()
			.then(() => {
				// await cf.alertUs(card, id, "deleted");
				return resolve(true);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function removeListingFromProduct(id, newOfferList, offerType) {
	return new Promise(function(resolve, reject) {
		marketdb
			.ref(`/products/${id}/${offerType}`)
			.set(newOfferList)
			.then(() => {
				return resolve(true);
			})
			.catch((e) => {
				return reject(e);
			});
	});
}

export function getAllFollowing() {
	return new Promise((resolve, reject) => {
		marketdb
			.ref(`/following/`)
			.once("value", (snapshot) => {
				return resolve(snapshot.val());
			})
			.catch((e) => {
				return reject(e);
			});
	});
}
