import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import WebsiteIcon from "@material-ui/icons/Explore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import PeopleIcon from "@material-ui/icons/People";
import OffersIcon from "@material-ui/icons/LocalOffer";
import OnboardingIcon from "@material-ui/icons/HowToReg";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PublishIcon from "@material-ui/icons/Publish";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: "#ffffff",
	},
	nested: {
		paddingLeft: theme.spacing(1) * 4,
	},
	icon: {
		color: "#35B2FF",
	},
});

class NestedList extends React.Component {
	state = {
		open: false,
	};

	handleClick = () => {
		this.setState((state) => ({ open: !state.open }));
	};

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Typography variant="h6" color="inherit" style={{ flex: 1 }}>
					<img
						src="https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
						style={{
							paddingLeft: "8px",
							paddingTop: "16px",
							width: "9.375em",
						}}
						alt="EnterOffer"
					/>
				</Typography>
				<List component="nav" className={classes.root} style={{ width: "16em" }}>
					<Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>{this.props.location.pathname === "/" ? <DashboardIcon className={classes.icon} /> : <DashboardIcon />}</ListItemIcon>
							<ListItemText primary="CSV Downloads" />
						</ListItem>
					</Link>
					<Link to="/offers" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>{this.props.location.pathname === "/offers" ? <OffersIcon className={classes.icon} /> : <OffersIcon />}</ListItemIcon>
							<ListItemText primary="Offers" />
						</ListItem>
					</Link>
					<Link to="/stats" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/stats" ? <BarChartIcon className={classes.icon} /> : <BarChartIcon />}
							</ListItemIcon>
							<ListItemText primary="Statistics" />
						</ListItem>
					</Link>
					<Link to="/custdata" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/custdata" ? <PeopleIcon className={classes.icon} /> : <PeopleIcon />}
							</ListItemIcon>
							<ListItemText primary="Customer Data" />
						</ListItem>
					</Link>
					<Link to="/onboarding" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/onboarding" ? <OnboardingIcon className={classes.icon} /> : <OnboardingIcon />}
							</ListItemIcon>
							<ListItemText primary="Retailer Onboarding Table" />
						</ListItem>
					</Link>
					<Link to="/mp-product-upload" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/mp-product-upload" ? <PublishIcon className={classes.icon} /> : <PublishIcon />}
							</ListItemIcon>
							<ListItemText primary="Marketplace Product Upload" />
						</ListItem>
					</Link>
					<Link to="/mp-products" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/mp-products" ? <LocalMallIcon className={classes.icon} /> : <LocalMallIcon />}
							</ListItemIcon>
							<ListItemText primary="Marketplace Products" />
						</ListItem>
					</Link>
					<Link to="/following-info" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								{this.props.location.pathname === "/following-info" ? <GroupAddIcon className={classes.icon} /> : <GroupAddIcon />}
							</ListItemIcon>
							<ListItemText primary="Following Info" />
						</ListItem>
					</Link>
				</List>
				<Divider />
				<List>
					<a href="https://enteroffer.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
						<ListItem button>
							<ListItemIcon>
								<WebsiteIcon />
							</ListItemIcon>
							<ListItemText primary="Website" />
						</ListItem>
					</a>
				</List>
			</div>
		);
	}
}

NestedList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(NestedList));
