import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { db } from '../firebase';
import Done from '@material-ui/icons/Done';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Clear from '@material-ui/icons/Clear';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OaklasLogoWhite } from '../assets';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import moment from 'moment';

function desc(a, b, orderBy) {
    return a[orderBy] < b[orderBy] ? 1 : -1;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'product', numeric: false, disablePadding: false, label: 'Product' },
    { id: 'currency', numeric: false, disablePadding: true, label: 'Currency' },
    {
        id: 'listPrice',
        numeric: false,
        disablePadding: true,
        label: 'List Price'
    },
    {
        id: 'costPrice',
        numeric: false,
        disablePadding: true,
        label: 'Cost Price'
    },
    {
        id: 'bidPrice',
        numeric: false,
        disablePadding: true,
        label: 'Offer Price'
    },
    {
        id: 'discountCalc',
        numeric: false,
        disablePadding: true,
        label: 'Discount (%)'
    },
    {
        id: 'submittedDate',
        numeric: false,
        disablePadding: false,
        label: 'Submission Date'
    },
    {
        id: 'transaction',
        numeric: false,
        disablePadding: true,
        label: 'Transaction successful'
    },
    {
        id: 'order',
        numeric: false,
        disablePadding: true,
        label: 'Order created'
    }
];

const rejectRows = rows.slice(0, -2);

const rowsWithoutResp = [
    { id: 'product', numeric: false, disablePadding: false, label: 'Product' },
    { id: 'currency', numeric: false, disablePadding: true, label: 'Currency' },
    {
        id: 'listPrice',
        numeric: false,
        disablePadding: true,
        label: 'List Price'
    },
    {
        id: 'costPrice',
        numeric: false,
        disablePadding: true,
        label: 'Cost Price'
    },
    {
        id: 'bidPrice',
        numeric: false,
        disablePadding: true,
        label: 'Offer Price'
    },
    {
        id: 'discountCalc',
        numeric: false,
        disablePadding: true,
        label: 'Discount (%)'
    },
    {
        id: 'submittedDate',
        numeric: false,
        disablePadding: false,
        label: 'Submission Date'
    },
    {
        id: 'transaction',
        numeric: false,
        disablePadding: true,
        label: 'Transaction successful'
    },
    {
        id: 'order',
        numeric: false,
        disablePadding: true,
        label: 'Order created'
    }
];
function getTotalCost(row) {
    let totalCost = 0;
    for (let i in row.products) {
        totalCost += Number(row.products[i].cost);
    }
    return totalCost;
}
class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    handleResponseChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { order, orderBy, transCol, orderCol, resCol } = this.props;
        var usedRows;
        if (!transCol && !orderCol) {
            usedRows = rejectRows;
        } else if (!resCol) {
            usedRows = rowsWithoutResp;
        } else {
            usedRows = rows;
        }
        return (
            <TableHead>
                <TableRow>
                    {usedRows.map(row => {
                        return (
                            <TableCell
                                style={{ textAlign: 'center' }}
                                key={row.id}
                                padding={
                                    row.disablePadding ? 'none' : 'default'
                                }
                                sortDirection={
                                    orderBy === row.id ? order : false
                                }
                            >
                                <Tooltip
                                    title='Sort'
                                    placement={
                                        row.numeric
                                            ? 'bottom-end'
                                            : 'bottom-start'
                                    }
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    transCol: PropTypes.bool,
    orderCol: PropTypes.bool,
    resCol: PropTypes.bool
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85)
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark
              },
    spacer: {
        flex: '1 1 100%'
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: '0 0 auto'
    }
});

let EnhancedTableToolbar = props => {
    const { classes } = props;

    return (
        <Toolbar className={classes.root}>
            <div className={classes.spacer} />
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1) * 2,
        boxShadow: 'none',
        borderRadius: '0'
    },
    table: {
        minWidth: 1020,
        textAlign: 'center'
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    divLogo: {
        paddingTop: '30px'
    },
    media: {
        // ⚠️ object-fit is not supported by IE 11.
        // objectFit: 'cover',
        // backgroundSize: 'cover',
        width: 'auto',
        height: '70px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
    },
    titleText: {
        paddingTop: '50px'
    },
    noItems: {
        // flexGrow: 1,
        // maxWidth: 1200,
        textAlign: 'center',
        margin: theme.spacing(1) 
    }
});

class EnhancedTable extends React.Component {
    didApproveOrReject(typeOfClose) {
        this.setState({ openMessage: false });
    }

    state = {
        order: 'desc',
        openSuccess: false,
        openMessage: false,
        showAreYouSureMessage: true,
        orderBy: 'submittedDate',
        selected: [],
        type: 0,
        storeName: '',
        typeName: 'Pending',
        data: [
            {
                id: -1,
                itemKey: '',
                discountCalc: '',
                currency: '',
                bidPrice: '',
                listPrice: '',
                inventory: '',
                product: '',
                email: '',
                submittedDate: '',
                approve: '',
                reject: ''
            }
        ],
        dataLength: 0,
        page: 0,
        rowsPerPage: 10,
        loading: true,
        functionType: '',
        row: {},
        offerResultTitle: '',
        offerResultText: ''
    };

    handleChange = (event, type) => {
        this.setState({ type });
        this.setState({ loading: true });
        if (type === 0) {
            this.getItemsFunc('pending', false);
            this.setState({ typeName: 'Pending' });
        } else if (type === 1) {
            this.getItemsFunc('approved', false);
            this.setState({ typeName: 'Approved' });
        } else if (type === 2) {
            this.getItemsFunc('rejected', false);
            this.setState({ typeName: 'Rejected' });
        } else if (type === 3) {
            this.getItemsFunc('expired', false);
            this.setState({ typeName: 'Expired' });
        }
    };

    createData(
        bidKey,
        discount,
        currency,
        bidPrice,
        listPrice,
        inventory,
        product,
        email,
        dateInNumbers,
        dateInWords,
        approve,
        reject,
        itemStatus,
        productID,
        variantID,
        payment,
        order
    ) {
        const { counter, numNoAction, numApproved, numReject } = this.state;

        this.setState({ counter: counter + 1 });
        if (!approve && !reject) {
            this.setState({ numNoAction: numNoAction + 1 });
        } else if (approve) {
            this.setState({ numApproved: numApproved + 1 });
        } else {
            this.setState({ numReject: numReject + 1 });
        }
        return {
            id: counter,
            bidKey,
            discount,
            currency,
            bidPrice,
            listPrice,
            inventory,
            product,
            email,
            dateInNumbers,
            dateInWords,
            approve,
            reject,
            itemStatus,
            productID,
            variantID,
            payment,
            order
        };
    }

    snapshotToArray(snapshot) {
        var returnArr = [];
        var i = 0;
        snapshot.forEach(function(childSnapshot) {
            var item = childSnapshot.val();
            item.itemStatus = '';
            if (item.approved === true && item.rejected === false) {
                item.itemStatus = 'approved';
            } else if (item.approved === false && item.rejected === true) {
                item.itemStatus = 'rejected';
            }

            item.key = childSnapshot.key;
            item.id = i;
            i = i + 1;
            returnArr.push(item);
        });
        return returnArr;
    }

    getItemsFunc(whichType, didClickUpdate) {
        db.getStoreBids(this.state.storeName, whichType)
            .then(snapshot => {
                if (snapshot !== 'noData') {
                    this.setState({
                        data: this.snapshotToArray(snapshot),
                        dataLength: this.snapshotToArray(snapshot).length,
                        loading: false
                    });
                }
                if (this.state.dataLength === 0) {
                    this.setState({ loading: false });
                }
                this.setState({ loading: false });
                if (didClickUpdate) {
                    this.setState({ openSuccess: true });
                }
                // if(this.snapshotToArray(snapshot).length > 0 && whichType === 'pending'){
                //     this.props.pendingCallback(true)
                // }
            })
            .catch(e => console.log('error', e));
    }

    componentDidMount() {
        if (!this.props.admin) {
            db.getUserStoreName()
                .then(snapshot => {
                    if (snapshot == null) {
                        return 'noData';
                    } else {
                        this.setState({ storeName: snapshot });
                        this.getItemsFunc('pending', false);
                    }
                })
                .catch(e => console.log('error', e));
        } else {
            if (this.props.storeName) {
                this.setState({ storeName: this.props.storeName });
                this.getItemsFunc('pending', false);
            }
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleClickSnackSuccess = () => {
        this.setState({ openSuccess: false });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes } = this.props;
        const {
            data,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            typeName,
            openSuccess,
            dataLength
        } = this.state;
        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, dataLength - page * rowsPerPage);

        return (
            <div style={{ width: '100%' }}>
                <Paper className={classes.root}>
                    <Button
                        fullWidth
                        onClick={() =>
                            this.getItemsFunc(
                                this.state.typeName.toLowerCase(),
                                true
                            )
                        }
                        style={{ background: '#ffffff', marginTop: '10px' }}
                    >
                        Check for Updates
                    </Button>

                    <Tabs
                        value={this.state.type}
                        onChange={this.handleChange}
                        variant='fullWidth'
                        indicatorColor='primary'
                        textColor='primary'
                    >
                        <Tab icon={<HelpIcon />} label='Pending' />
                        <Tab icon={<CheckCircle />} label='Approved' />
                        <Tab icon={<CancelIcon />} label='Rejected' />
                        <Tab icon={<WatchLaterIcon />} label='Expired' />
                    </Tabs>

                    <EnhancedTableToolbar numSelected={selected.length} />
                    <div className={classes.tableWrapper}>
                        {this.state.loading ? (
                            <CircularProgress
                                size={50}
                                style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: '50px'
                                }}
                            />
                        ) : data.length === 0 ? (
                            <div
                                className={classes.noItems}
                                style={{
                                    // marginBottom: '300px',
                                    height: 51.5 * emptyRows
                                }}
                            >
                                <div className={classes.divLogo}>
                                    <CardMedia
                                        component='img'
                                        className={classes.media}
                                        width='auto'
                                        image={OaklasLogoWhite}
                                        style={{
                                            display: 'none'
                                        }}
                                    />
                                    <Typography
                                        className={classes.titleText}
                                        variant='subtitle1'
                                        style={{
                                            color: '#3e3e3e',
                                            fontSize: '1.5em',
                                            paddingTop: '0px'
                                        }}
                                    >
                                        No Items In {this.state.typeName}
                                    </Typography>

                                    {typeName === 'Pending' ? (
                                        <Typography
                                            variant='subtitle1'
                                            style={{
                                                color: '#7f7f7f'
                                            }}
                                        >
                                            Great Work!
                                        </Typography>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <Table
                                className={classes.table}
                                aria-labelledby='tableTitle'
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={dataLength}
                                    transCol={
                                        this.state.typeName === 'Rejected' ||
                                        this.state.typeName === 'Expired'
                                            ? false
                                            : true
                                    }
                                    orderCol={
                                        this.state.typeName === 'Rejected' ||
                                        this.state.typeName === 'Expired'
                                            ? false
                                            : true
                                    }
                                    resCol={
                                        this.state.typeName === 'Approved'
                                            ? false
                                            : true
                                    }
                                />
                                <TableBody>
                                    {stableSort(
                                        data,
                                        getSorting(order, orderBy)
                                    )
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map(n => {
                                            if (n.id !== -1) {
                                                if (n.itemCount > 1) {
                                                    var obj = {};
                                                    for (let key in n.products) {
                                                        obj[key] =
                                                            n.products[key];
                                                    }
                                                }
                                                const isSelected = this.isSelected(
                                                    n.id
                                                );
                                                return (
                                                    <Fragment>
                                                        <TableRow
                                                            role='checkbox'
                                                            aria-checked={
                                                                isSelected
                                                            }
                                                            tabIndex={-1}
                                                            key={n.id}
                                                        >
                                                            {n.itemCount > 1 ? (
                                                                <TableCell
                                                                    style={{
                                                                        paddingLeft:
                                                                            '4%'
                                                                    }}
                                                                >
                                                                    Bundle (
                                                                    {
                                                                        n.itemCount
                                                                    }
                                                                    )
                                                                    {n.cartAbandoned ? (
                                                                        <Tooltip title='Cart Abandonment Offer'>
                                                                            <span
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'super',
                                                                                    fontSize:
                                                                                        '0.8em',
                                                                                    color:
                                                                                        '#22c78e',
                                                                                    cursor:
                                                                                        'pointer'
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                CA
                                                                            </span>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </TableCell>
                                                            ) : n.products !==
                                                                  undefined &&
                                                              n.products !==
                                                                  null ? (
                                                                <TableCell
                                                                    style={{
                                                                        paddingLeft:
                                                                            '4%'
                                                                    }}
                                                                >
                                                                    {
                                                                        n
                                                                            .products[0]
                                                                            .productName
                                                                    }
                                                                    {n.cartAbandoned ? (
                                                                        <Tooltip title='Cart Abandonment Offer'>
                                                                            <span
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'super',
                                                                                    fontSize:
                                                                                        '0.8em',
                                                                                    color:
                                                                                        '#22c78e',
                                                                                    cursor:
                                                                                        'pointer'
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                CA
                                                                            </span>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    style={{
                                                                        paddingLeft:
                                                                            '4%'
                                                                    }}
                                                                >
                                                                    {
                                                                        n.productName
                                                                    }
                                                                    {n.cartAbandoned ? (
                                                                        <Tooltip title='Cart Abandonment Offer'>
                                                                            <span
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'super',
                                                                                    fontSize:
                                                                                        '0.8em',
                                                                                    color:
                                                                                        '#22c78e',
                                                                                    cursor:
                                                                                        'pointer'
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                CA
                                                                            </span>
                                                                        </Tooltip>
                                                                    ) : null}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {n.currency}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {Number(
                                                                    n.listPrice
                                                                ).toFixed(2)}
                                                            </TableCell>
                                                            {n.itemCount ===
                                                            1 ? (
                                                                <TableCell
                                                                    style={{
                                                                        textAlign:
                                                                            'center'
                                                                    }}
                                                                >
                                                                    {n
                                                                        .products[0]
                                                                        .cost
                                                                        ? Number(
                                                                              n
                                                                                  .products[0]
                                                                                  .cost
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : null}
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell
                                                                    style={{
                                                                        textAlign:
                                                                            'center'
                                                                    }}
                                                                >
                                                                    {getTotalCost(
                                                                        n
                                                                    )
                                                                        ? Number(
                                                                              getTotalCost(
                                                                                  n
                                                                              )
                                                                          ).toFixed(
                                                                              2
                                                                          )
                                                                        : null}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {isNaN(
                                                                    Number(
                                                                        n.bidPrice
                                                                    )
                                                                )
                                                                    ? null
                                                                    : Number(
                                                                          n.bidPrice
                                                                      ).toFixed(
                                                                          2
                                                                      )}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {isNaN(
                                                                    Number(
                                                                        n.discountCalc
                                                                    )
                                                                )
                                                                    ? null
                                                                    : Number(
                                                                          n.discountCalc
                                                                      ).toFixed(
                                                                          2
                                                                      )}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    textAlign:
                                                                        'center'
                                                                }}
                                                            >
                                                                {moment(
                                                                    n.submittedDate
                                                                ).format(
                                                                    'MMM DD YYYY h:mm:ss a'
                                                                )}
                                                            </TableCell>
                                                            {this.state
                                                                .typeName ===
                                                                'Rejected' ||
                                                            this.state
                                                                .typeName ===
                                                                'Expired' ? null : (
                                                                <TableCell>
                                                                    {n.payment !==
                                                                        null &&
                                                                    n.payment ===
                                                                        false ? (
                                                                        <div
                                                                            color='secondary'
                                                                            style={{
                                                                                textAlign:
                                                                                    'center'
                                                                            }}
                                                                            value={
                                                                                'a'
                                                                            }
                                                                        >
                                                                            <Clear />
                                                                        </div>
                                                                    ) : null}
                                                                    {n.payment !==
                                                                        null &&
                                                                    n.payment ===
                                                                        true ? (
                                                                        <div
                                                                            color='secondary'
                                                                            style={{
                                                                                textAlign:
                                                                                    'center'
                                                                            }}
                                                                            value={
                                                                                'a'
                                                                            }
                                                                        >
                                                                            <Done />
                                                                        </div>
                                                                    ) : null}
                                                                </TableCell>
                                                            )}
                                                            {this.state
                                                                .typeName ===
                                                                'Rejected' ||
                                                            this.state
                                                                .typeName ===
                                                                'Expired' ? null : (
                                                                <TableCell>
                                                                    {n.order !==
                                                                        null &&
                                                                    n.order ===
                                                                        false ? (
                                                                        <div
                                                                            color='secondary'
                                                                            style={{
                                                                                textAlign:
                                                                                    'center'
                                                                            }}
                                                                            value={
                                                                                'a'
                                                                            }
                                                                        >
                                                                            <Clear />
                                                                        </div>
                                                                    ) : null}
                                                                    {n.order !==
                                                                        null &&
                                                                    n.order ===
                                                                        true ? (
                                                                        <div
                                                                            color='secondary'
                                                                            style={{
                                                                                textAlign:
                                                                                    'center'
                                                                            }}
                                                                            value={
                                                                                'a'
                                                                            }
                                                                        >
                                                                            <Done />
                                                                        </div>
                                                                    ) : null}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                        {n.itemCount > 1
                                                            ? Object.keys(
                                                                  obj
                                                              ).map(key => (
                                                                  <TableRow
                                                                      role='checkbox'
                                                                      aria-checked={
                                                                          isSelected
                                                                      }
                                                                      tabIndex={
                                                                          -1
                                                                      }
                                                                      key={
                                                                          obj[
                                                                              key
                                                                          ].id
                                                                      }
                                                                      selected='true'
                                                                  >
                                                                      <TableCell
                                                                          style={{
                                                                              paddingLeft:
                                                                                  '5%'
                                                                          }}
                                                                      >
                                                                          {
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .productName
                                                                          }
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .currency
                                                                          }
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {Number(
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .listPrice
                                                                          ).toFixed(
                                                                              2
                                                                          )}
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {obj[
                                                                              key
                                                                          ].cost
                                                                              ? Number(
                                                                                    obj[
                                                                                        key
                                                                                    ]
                                                                                        .cost
                                                                                ).toFixed(
                                                                                    2
                                                                                )
                                                                              : null}
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {n.cartAbandoned
                                                                              ? '-'
                                                                              : Number(
                                                                                    obj[
                                                                                        key
                                                                                    ]
                                                                                        .bidPrice
                                                                                ).toFixed(
                                                                                    2
                                                                                )}
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {n.cartAbandoned
                                                                              ? '-'
                                                                              : Number(
                                                                                    obj[
                                                                                        key
                                                                                    ]
                                                                                        .discountCalc
                                                                                ).toFixed(
                                                                                    2
                                                                                )}
                                                                      </TableCell>
                                                                      <TableCell
                                                                          style={{
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      ></TableCell>
                                                                      {this
                                                                          .state
                                                                          .typeName !==
                                                                      'Approved' ? (
                                                                          <TableCell>
                                                                              <div>
                                                                                  {(!obj[
                                                                                      key
                                                                                  ]
                                                                                      .approved &&
                                                                                      obj[
                                                                                          key
                                                                                      ]
                                                                                          .rejected) ||
                                                                                  this
                                                                                      .state
                                                                                      .typeName ===
                                                                                      'Expired' ? (
                                                                                      <div
                                                                                          color='secondary'
                                                                                          style={{
                                                                                              textAlign:
                                                                                                  'center'
                                                                                          }}
                                                                                          value={
                                                                                              'a'
                                                                                          }
                                                                                      >
                                                                                          <Clear />
                                                                                      </div>
                                                                                  ) : null}
                                                                                  {obj[
                                                                                      key
                                                                                  ]
                                                                                      .approved &&
                                                                                  !obj[
                                                                                      key
                                                                                  ]
                                                                                      .rejected ? (
                                                                                      <div
                                                                                          color='secondary'
                                                                                          style={{
                                                                                              textAlign:
                                                                                                  'center'
                                                                                          }}
                                                                                          value={
                                                                                              'b'
                                                                                          }
                                                                                      >
                                                                                          <Done />
                                                                                      </div>
                                                                                  ) : null}
                                                                              </div>
                                                                          </TableCell>
                                                                      ) : null}
                                                                      {this
                                                                          .state
                                                                          .typeName ===
                                                                          'Rejected' ||
                                                                      this.state
                                                                          .typeName ===
                                                                          'Expired' ? null : (
                                                                          <TableCell>
                                                                              {obj[
                                                                                  key
                                                                              ]
                                                                                  .payment !==
                                                                                  null &&
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .payment ===
                                                                                  false ? (
                                                                                  <div
                                                                                      color='secondary'
                                                                                      style={{
                                                                                          textAlign:
                                                                                              'center'
                                                                                      }}
                                                                                      value={
                                                                                          'a'
                                                                                      }
                                                                                  >
                                                                                      <Clear />
                                                                                  </div>
                                                                              ) : null}
                                                                              {obj[
                                                                                  key
                                                                              ]
                                                                                  .payment !==
                                                                                  null &&
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .payment ===
                                                                                  true ? (
                                                                                  <div
                                                                                      color='secondary'
                                                                                      style={{
                                                                                          textAlign:
                                                                                              'center'
                                                                                      }}
                                                                                      value={
                                                                                          'a'
                                                                                      }
                                                                                  >
                                                                                      <Done />
                                                                                  </div>
                                                                              ) : null}
                                                                          </TableCell>
                                                                      )}
                                                                      {this
                                                                          .state
                                                                          .typeName ===
                                                                          'Rejected' ||
                                                                      this.state
                                                                          .typeName ===
                                                                          'Expired' ? null : (
                                                                          <TableCell>
                                                                              {obj[
                                                                                  key
                                                                              ]
                                                                                  .order !==
                                                                                  null &&
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .order ===
                                                                                  false ? (
                                                                                  <div
                                                                                      color='secondary'
                                                                                      style={{
                                                                                          textAlign:
                                                                                              'center'
                                                                                      }}
                                                                                      value={
                                                                                          'a'
                                                                                      }
                                                                                  >
                                                                                      <Clear />
                                                                                  </div>
                                                                              ) : null}
                                                                              {obj[
                                                                                  key
                                                                              ]
                                                                                  .order !==
                                                                                  null &&
                                                                              obj[
                                                                                  key
                                                                              ]
                                                                                  .order ===
                                                                                  true ? (
                                                                                  <div
                                                                                      color='secondary'
                                                                                      style={{
                                                                                          textAlign:
                                                                                              'center'
                                                                                      }}
                                                                                      value={
                                                                                          'a'
                                                                                      }
                                                                                  >
                                                                                      <Done />
                                                                                  </div>
                                                                              ) : null}
                                                                          </TableCell>
                                                                      )}
                                                                  </TableRow>
                                                              ))
                                                            : null}
                                                    </Fragment>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}

                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 49 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    <TablePagination
                        component='div'
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page'
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page'
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={openSuccess}
                    onClose={this.handleClickSnackSuccess}
                    autoHideDuration={3000}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id='message-id'>Page Updated</span>}
                />
            </div>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);
