import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { fb } from "../firebase";
import { Typography, Snackbar, CircularProgress } from "@material-ui/core";
import Moment from "moment";
import _ from "lodash";

class AvgCustSpend extends Component {
	state = {
		approved: {},
		storeName: "",
		value: "",
		open: false,
		allCustomers: {},
		loading: true,
		range: 6
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		let arr = {};
		let allcustomers = {};
		for (var i = 0; i < this.props.range; i++) {
			arr[
				Moment()
					.subtract(i, "months")
					.month()
			] = 0;
		}
		fb.getAllCustomers()
			.then(allcusts => {
				allcusts = allcusts.data;
				allcustomers = allcusts;
				this.setState({
					allCustomers: allcustomers,
					approved: arr,
					range: this.props.range
				});
				this.updateState(arr, this.props.range);
			})
			.catch(e => {
				console.log(e);
			});
	}
	componentWillReceiveProps(props) {
		this.setState({ range: props.range });
		this.updateState(this.state.approved, props.range);
	}

	updateState(arr, range) {
		let allstoreval = this.props.offers;
		for (let store in allstoreval) {
			if (store !== "productsRUs" && store !== "bestFruits" && store !== "undefined" && store !== "approved" && store !== "apparelForYou") {
				for (let section in allstoreval[store]) {
					if (section === "approved") {
						for (let key in allstoreval[store][section]) {
							if (allstoreval[store][section][key].payment && allstoreval[store][section][key].order && Moment().diff(allstoreval[store][section][key].submittedDate, "months") < range) {
								var month = Moment(allstoreval[store][section][key].submittedDate).month();
								let bid = Number(allstoreval[store][section][key].bidPrice);
								arr[month] = arr[month] + bid || 0;
							}
						}
					}
				}
			}
		}
		let relofferedcusts = _.pickBy(this.state.allCustomers, (value, key) => {
			return value.stores;
		});
		for (let uid in relofferedcusts) {
			relofferedcusts[uid].stores = _.omitBy(relofferedcusts[uid].stores, (value, key) => {
				return (
					key === "productsRUs" ||
					key === "bestFruits" ||
					key === "apparelForYou" ||
					// key === "undefined" ||
					key === "productsRU" ||
					key === "oaklas" ||
					key === "storeName7" ||
					key === "luma" ||
					key === "ice"
				);
			});
		}
		let offerCountPM = {};
		for (var i = 0; i < range; i++) {
			offerCountPM[
				Moment()
					.subtract(i, "months")
					.month()
			] = 0;
		}
		for (let uid in relofferedcusts) {
			let userOfferCountPM = [];
			for (var j = 0; j < range; j++) {
				userOfferCountPM[
					Moment()
						.subtract(j, "months")
						.month()
				] = 0;
			}
			if (relofferedcusts[uid].stores) {
				for (let store in relofferedcusts[uid].stores) {
					relofferedcusts[uid].stores[store] = _.pickBy(relofferedcusts[uid].stores[store], (value, key) => {
						return key === "approved";
					});

					for (let entryKey in relofferedcusts[uid].stores[store]["approved"]) {
						let month = Moment(relofferedcusts[uid].stores[store]["approved"][entryKey].submittedDate).month();
						// console.log(
						// 	Moment(relofferedcusts[uid].stores[store]["approved"][entryKey].submittedDate).format("MMM Do YYYY"),
						// 	store,
						// 	relofferedcusts[uid].stores[store]["approved"][entryKey]
						// );
						if (
							relofferedcusts[uid].stores[store]["approved"][entryKey].payment === true &&
							Moment().diff(relofferedcusts[uid].stores[store]["approved"][entryKey].submittedDate, "months") < range
						) {
							userOfferCountPM[month]++;
						}
					}
				}
			}
			for (let m in userOfferCountPM) {
				if (userOfferCountPM[m] >= 1) {
					offerCountPM[m]++;
				}
			}
		}
		let brr = Object.assign({}, arr);
		for (let m in brr) {
			brr[m] = offerCountPM[m] === 0 ? 0 : Number(brr[m] / offerCountPM[m]).toFixed(3);
			brr[m] = Number(brr[m]);
		}
		// console.log(brr, arr, offerCountPM);
		this.setState({ approved: brr, loading: false });
	}

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		var chartLabels = [];
		var cD = [];
		for (let i = this.props.range - 1; i >= 0; i--) {
			let lbl = Moment()
				.subtract(i, "months")
				.format("MMM");
			chartLabels.push(lbl);
			cD.push(
				this.state.approved[
					Moment()
						.subtract(i, "months")
						.month()
				]
			);
		}
		var chartData = {
			labels: chartLabels,
			datasets: [
				{
					label: "Avg Customer Spend",
					data: cD,
					borderWidth: 1,
					borderColor: "rgba(42, 228, 155, 1)",
					backgroundColor: "rgba(42, 228, 155, .13)",
					fill: false
				}
			]
		};
		return (
			<div
				style={{
					verticalAlign: "middle",
					display: "grid"
				}}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontWeight: 700,
							fontSize: "1em"
						}}>
						Average Customer Spend
					</Typography>
				</div>
				{this.state.loading ? (
					<div style={{ textAlign: "center" }}>
						<CircularProgress size={50} />
					</div>
				) : (
					<Bar
						data={chartData}
						options={{
							scales: {
								yAxes: [
									{
										ticks: {
											min: 0,
											callback: function(value) {
												if (value % 1 === 0) {
													return value;
												}
											}
										}
									}
								]
							}
						}}
					/>
				)}

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'>Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default AvgCustSpend;
