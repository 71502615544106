import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { db, fb } from "../firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MenuItem, TextField, Paper, Grid } from "@material-ui/core";
import { SalesBarChart, RevenueBarChart, AvgCustSpend, OffersBarChart, AllOffersBarChart } from "../components";
import _ from "lodash";
import moment from "moment";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	media: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: "cover",
	},
	progress: {
		margin: theme.spacing(1),
		marginTop: "30vh",
	},
	textField: {
		width: 200,
	},
});

class Statistics extends Component {
	state = {
		storeName: "",
		modalOpen: false,
		loading: true,
		target: "",
		currencyListString: "-",
		allStores: [],
		expanded: null,
		allOffers: {},
		allCustomers: {},
		admin: false,
		transactionAmount: 0,
		customerCount: 0,
		recurringCustomers: 0,
		approvalAmount: 0,
		conversions: 0,
		customernooffercount: 0,
		queryString: "",
		fromTime: moment().subtract(5, "years").format(),
		toTime: moment().format(),
		allRules: {},
		monthRange: 6,
	};
	handleChange = (name) => (event) => {
		if (name === "fromTime" || name === "toTime") {
			let date = moment(event.target.value).format();
			this.setState({ [name]: date });
		} else {
			this.setState({ [name]: event.target.value });
		}
	};

	snapshotToArray(snapshot) {
		var returnArr = [];

		snapshot.forEach(function (childSnapshot) {
			var item = childSnapshot.val();
			item.key = childSnapshot.key;

			returnArr.push(item);
		});
		return returnArr;
	}

	componentDidMount() {
		let alloffers = {};
		let allcustomers = {};
		let stores = [];
		let allrules = {};
		fb.getAllBids()
			.then((allstoreval) => {
				allstoreval = allstoreval.data;
				alloffers = allstoreval;
				_.map(alloffers, (val, key) => {
					if (key !== "productsRUs" && key !== "bestFruits" && key !== "undefined" && key !== "approved" && key !== "apparelForYou") {
						let obj = {};
						obj["value"] = key;
						obj["label"] = _.startCase(key);
						stores.push(obj);
					}
				});
				return fb.getAllCustomers();
			})
			.then((allcusts) => {
				allcusts = allcusts.data;
				allcustomers = allcusts;

				this.setState({
					loading: false,
					allOffers: alloffers,
					allStores: stores,
					allCustomers: allcustomers,
				});
			})
			.catch((e) => {
				console.log(e);
			});
		db.getAllRules().then((rules) => {
			_.map(rules.val(), (val, key) => {
				if (key !== "productsRUs" && key !== "bestFruits" && key !== "undefined" && key !== "approved" && key !== "apparelForYou") {
					allrules[key] = val;
				}
			});
			this.setState({
				allRules: allrules,
			});
		});
	}

	handleOpen = (target) => {
		this.setState({
			modalOpen: true,
			target: target,
		});
	};
	handleClose = () => {
		this.setState({
			modalOpen: false,
			target: "",
		});
	};
	handleChangePanel = (panel) => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false,
		});
	};

	getOfferStats = () => {
		const { queryString, allOffers, fromTime, toTime } = this.state;
		let allstoreval =
			queryString === ""
				? allOffers
				: _.pickBy(allOffers, (value, key) => {
						return key.toLowerCase().includes(queryString.toLowerCase());
				  });
		allstoreval = _.omitBy(allstoreval, (value, key) => {
			return key === "productsRUs" || key === "bestFruits" || key === "undefined" || key === "approved" || key === "apparelForYou";
		});
		for (let store in allstoreval) {
			allstoreval[store] = _.pickBy(allstoreval[store], (value, key) => {
				return key === "approved" || key === "rejected" || key === "pending";
			});
		}
		let fromTimeStamp = moment(fromTime);
		let toTimeStamp = moment(toTime);

		//filtering result by these
		for (let store in allstoreval) {
			for (let section in allstoreval[store]) {
				allstoreval[store][section] = _.pickBy(allstoreval[store][section], (value, key) => {
					let checkDate = moment(value.submittedDate);
					return moment(checkDate).isSameOrAfter(fromTimeStamp) && moment(checkDate).isSameOrBefore(toTimeStamp);
				});
			}
		}

		let conversions = 0;
		let approvalamount = 0;
		let transactionamount = 0;
		let transactionPMamount = 0;
		let offersPM = 0;
		let avgDisc = 0;
		let avgAccDisc = 0;
		let transactedcount = 0;
		let acceptedAboveCount = 0;
		let greyArea = 0;
		let totalCount = 0;
		let totalMoreVal = 0;
		for (let store in allstoreval) {
			for (let section in allstoreval[store]) {
				conversions += Object.keys(allstoreval[store][section]).length;
				if (section === "approved") {
					for (let key in allstoreval[store][section]) {
						approvalamount++;
						if (allstoreval[store][section][key].payment && allstoreval[store][section][key].order) {
							if (
								moment(allstoreval[store][section][key].submittedDate).isSameOrBefore(moment()) &&
								moment(allstoreval[store][section][key].submittedDate).isSameOrAfter(moment().subtract(30, "days"))
							) {
								transactionPMamount += Number(allstoreval[store][section][key].bidPrice);
								offersPM += 1;
							}
							transactionamount += Number(allstoreval[store][section][key].bidPrice);
							avgAccDisc += Number(allstoreval[store][section][key].discountCalc);
							transactedcount++;
							if (_.has(this.state.allRules, store)) {
								_.map(allstoreval[store][section][key].products, (val, k) => {
									if (this.state.allRules[store][val.productID] !== undefined) {
										let d = Number(100 - val.discountCalc);
										let a = Number(this.state.allRules[store][val.productID].acceptAbove.amount);
										let r = Number(this.state.allRules[store][val.productID].autoRejectBelow);
										if (d > a) {
											acceptedAboveCount++;
											totalMoreVal += d - a;
										} else {
											greyArea += d - r;
										}
									}
								});
							}
						}
					}
				}
				for (let key in allstoreval[store][section]) {
					if (!isNaN(Number(allstoreval[store][section][key].discountCalc))) {
						totalCount++;
						avgDisc += Number(allstoreval[store][section][key].discountCalc);
					}
				}
			}
		}
		if (totalCount === 0) {
			avgDisc = 0;
		} else {
			avgDisc = avgDisc / totalCount;
		}
		if (transactedcount === 0) {
			avgAccDisc = 0;
		} else {
			avgAccDisc = avgAccDisc / transactedcount;
		}
		return {
			conversions: conversions,
			transactionAmount: transactionamount,
			transactionPMAmount: transactionPMamount,
			offersPM: offersPM,
			transactedCount: transactedcount,
			acceptedAboveCount: acceptedAboveCount,
			greyArea: greyArea,
			approvalAmount: approvalamount,
			averageDiscount: avgDisc,
			averageAcceptedDiscount: avgAccDisc,
			totalMoreVal: totalMoreVal,
		};
	};

	getCustomerStats = () => {
		const { queryString, allCustomers, fromTime, toTime } = this.state;
		let allcusts =
			queryString === ""
				? allCustomers
				: _.pickBy(allCustomers, (value, key) => {
						if (value.meta !== undefined && value.meta !== null) {
							if (value.meta.whichStore !== undefined && value.meta.whichStore !== null) {
								if (value.meta.whichStore.toLowerCase().includes(queryString.toLowerCase())) {
									return true;
								}
							}
						}
						if (value.stores !== undefined && value.stores !== null) {
							let storeNamesArr = Object.keys(value.stores);
							for (let storeName in storeNamesArr) {
								if (storeName.toLowerCase().includes(queryString.toLowerCase())) {
									return true;
								}
							}
						}
						return false;
				  });
		let fromTimeStamp = moment(fromTime);
		let toTimeStamp = moment(toTime);

		let recustcount = 0;
		let customercount = 0;
		let recustcountPM = 0;
		let customercountPM = 0;
		let customernooffercount = 0;

		let relofferedcusts = _.pickBy(allcusts, (value, key) => {
			return value.stores;
		});
		for (let uid in relofferedcusts) {
			relofferedcusts[uid].stores = _.omitBy(relofferedcusts[uid].stores, (value, key) => {
				return key === "productsRUs" || key === "bestFruits" || key === "apparelForYou";
			});
		}
		for (let uid in relofferedcusts) {
			let userOfferCount = 0;
			let userOfferCountPM = 0;
			if (relofferedcusts[uid].stores) {
				for (let store in relofferedcusts[uid].stores) {
					if (userOfferCount >= 2) {
						break;
					}
					relofferedcusts[uid].stores[store] = _.pickBy(relofferedcusts[uid].stores[store], (value, key) => {
						return key === "approved" || key === "rejected" || key === "pending";
					});

					for (let section in relofferedcusts[uid].stores[store]) {
						if (userOfferCount >= 2) {
							break;
						}
						for (let entryKey in relofferedcusts[uid].stores[store][section]) {
							if (userOfferCount >= 2) {
								break;
							}
							userOfferCount++;

							if (
								section === "approved" &&
								moment(relofferedcusts[uid].stores[store][section][entryKey].submittedDate).isSameOrBefore(moment()) &&
								moment(relofferedcusts[uid].stores[store][section][entryKey].submittedDate).isSameOrAfter(moment().subtract(30, "days"))
							) {
								userOfferCountPM++;
							}
						}
					}
				}
			}
			if (userOfferCount === 1) {
				customercount++;
			}
			if (userOfferCount >= 2) {
				recustcount++;
			}
			if (userOfferCountPM === 1) {
				customercountPM++;
			}
			if (userOfferCountPM >= 2) {
				recustcountPM++;
			}
		}
		let noofferedcusts = _.omitBy(allcusts, (value, key) => {
			if (!value.meta) {
				return true;
			}
			return (
				value.stores ||
				value.meta.whichStore === "productsRUs" ||
				value.meta.whichStore === "bestFruits" ||
				value.meta.whichStore === "undefined" ||
				value.meta.whichStore === "approved" ||
				value.meta.whichStore === "apparelForYou"
			);
		});
		noofferedcusts = _.pickBy(noofferedcusts, (value, key) => {
			let checkDate = moment(value.meta.signUpDate);
			if (moment(checkDate).isSameOrAfter(fromTimeStamp) && moment(checkDate).isSameOrBefore(toTimeStamp)) {
				return true;
			} else {
				return false;
			}
		});

		customernooffercount = Object.keys(noofferedcusts).length;
		return {
			customerCount: customercount,
			recurringCustomers: recustcount,
			customerCountPM: customercountPM,
			recurringCustomersPM: recustcountPM,
			customernooffercount: customernooffercount,
		};
	};

	render() {
		const { classes } = this.props;
		const { allStores, allOffers, monthRange } = this.state;
		const loading = this.state.loading;
		const offerStats = this.getOfferStats();
		const customerStats = this.getCustomerStats();
		const conversions = offerStats.conversions;
		const transactionAmount = offerStats.transactionAmount;
		const transactionPMAmount = offerStats.transactionPMAmount;
		const offersPM = offerStats.offersPM;
		const acceptedAboveCount = offerStats.acceptedAboveCount;
		const approvalAmount = offerStats.approvalAmount;
		const averageDiscount = offerStats.averageDiscount;
		const averageAcceptedDiscount = offerStats.averageAcceptedDiscount;
		const betterMarginVal = offerStats.totalMoreVal;
		const greyArea = offerStats.greyArea;
		const transactedCount = offerStats.transactedCount;
		const customerCount = customerStats.customerCount;
		const recurringCustomers = customerStats.recurringCustomers;
		const customerCountPM = customerStats.customerCountPM;
		const recurringCustomersPM = customerStats.recurringCustomersPM;
		const customernooffercount = customerStats.customernooffercount;

		return (
			<div className={classes.root}>
				{loading ? (
					<div style={{ textAlign: "center" }}>
						<CircularProgress size={100} className={classes.progress} />
					</div>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<TextField
								select
								label="All Stores"
								className={classes.textField}
								value={this.state.queryString}
								onChange={this.handleChange("queryString")}
								SelectProps={{
									MenuProps: {
										className: classes.menu,
									},
								}}
								helperText="Please select your store"
								margin="normal"
							>
								{allStores.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={3} style={{ padding: "32px 16px" }}>
							<TextField
								id="date"
								label="From Time"
								type="date"
								onChange={this.handleChange("fromTime")}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={3} style={{ padding: "32px" }}>
							<TextField
								id="date"
								label="To Time"
								type="date"
								onChange={this.handleChange("toTime")}
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Number of offers submitted:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{conversions}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Transaction amount:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{transactionAmount}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Number of Offers Approved:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{approvalAmount}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Approval Rate:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{((approvalAmount / conversions) * 100 || 0).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Better Margin Average :
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{(betterMarginVal / acceptedAboveCount).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Better Margin Average(incl. grey):
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{((betterMarginVal + greyArea) / transactedCount).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Customer Count (single use):
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{customerCount}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Recurring customers:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{recurringCustomers}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Total Customer Count:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{customerCount + recurringCustomers}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Customer Re-engagement:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{Number((recurringCustomers / (customerCount + recurringCustomers)) * 100).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Customer with no offers:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{customernooffercount}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Average discount:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{averageDiscount.toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Average accepted discount:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{averageAcceptedDiscount.toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Average customer spend:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{(transactionAmount / (customerCount + recurringCustomers) || 0).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Average customer spend (past 30 days):
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{(transactionPMAmount / (customerCountPM + recurringCustomersPM) || 0).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								Revenue per customer:
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								{((transactionAmount / (customerCount + recurringCustomers)) * 0.02 || 0).toFixed(2)}
							</Paper>
						</Grid>
						<Grid item xs={12} style={{ textAlign: "center" }}>
							<TextField
								label="Months (1-12)"
								type="number"
								value={monthRange}
								error={monthRange < 0 || monthRange > 12 ? true : false}
								onChange={this.handleChange("monthRange")}
								className={classes.textField}
								InputProps={{ inputProps: { min: 1, max: 12 } }}
								InputLabelProps={{
									shrink: true,
								}}
								variant="standard"
							/>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<RevenueBarChart offers={allOffers} range={Number(monthRange)} />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<SalesBarChart offers={allOffers} range={Number(monthRange)} />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<AvgCustSpend offers={allOffers} range={Number(monthRange)} />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<OffersBarChart offers={allOffers} range={Number(monthRange)} />
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper elevation={0} className={classes.paper}>
								<AllOffersBarChart offers={allOffers} range={Number(monthRange)} />
							</Paper>
						</Grid>
					</Grid>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(Statistics);
