import { functions } from "./firebase";

export function listingApproved(email, submitedDate,productId, productName, image, sellId){
	const f = functions.httpsCallable("marketplaceEmails-listingApproved");
	let d = {email, submitedDate,productId, productName, image, sellId};
	return new Promise((resolve, reject) => {
		f(d)
			.then((res) => {
				resolve(res);
			})
			.catch((e) => {
				console.error(e);
				reject(e);
			});
	});
}
