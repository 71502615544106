import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Grid,
	TextField,
	Divider,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Badge,
	Dialog,
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { userfb, db } from "../firebase";
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import { AllListingsTable } from "../components";
import moment from "moment";
import { listingApproved } from "../firebase/cf"
const styles = (theme) => ({
	root: {
		minHeight: "75vh",
		flexGrow: 1,
		fontFamily: theme.palette.fontFamily,
	},

	button: {
		border: "1px solid #e3e3e3",
		borderRadius: "0.6em",
		backgroundColor: "transparent",
		color: "#929292cc",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: 155,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main + "!important",
		},
	},
	input: {
		display: "none",
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "0.9em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "0.9em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: "#e3e3e3",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"&:hover fieldset": {
				border: "1px solid #92929288",
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1em" },
	},
	formControl: {
		margin: "auto",
		minWidth: 200,
	},

	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "1em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
});

function makeList(products) {
	var v = Object.values(products);
	return v;
}
function MpProductUploader(props) {
	const { classes } = props;

	const [images, setImages] = useState([]);
	const [imageUrl, setImageUrl] = useState("");
	const [imageLoading, setImageLoading] = useState(false);

	const [products, setProducts] = useState({});
	const [selectedProd, setSelectedProd] = useState({});
	const [categoryList, setCategoryList] = useState({});

	const [loading, setLoading] = useState(true);

	const [copiedOver, setCopiedOver] = useState(false);

	const [values, setValues] = useState({
		productName: "",
		id: "",
		img: "",
		metaDesc: "",
		keywords: "",
		dateAdded: "",
		sellOffers: [],
		buyOffers: [],
		category: "",
		lastSoldNew: "",
		lastSoldUsed: "",
		rrp: "",
		trending: false,
		brand: "",
		entrupy: false,
		gender: "",
		//buyOfferStuff
		offer: "", //also sellOfferStuff
		condition: "New", //also sellOfferStuff
		location: "", //also sellOfferStuff
		imgs: [],
		videoUrl: "",
		videoPhotoUrl: "",
		videoDuration: 0,
		productDesc: "",
		sellId: "",
		sellerId: "",
		sellerEmail: "",
		//sellOfferStuff
		buyId: "",
		url: "",
		untilDate: "",
		color: [],
		ownedFor: 0,
	});

	const [extras, setExtras] = useState({
		case: false,
		packaging: false,
		receipt: false,
		cert: false,
		dustbag: false,
		tags: false,
	});

	useEffect(() => {
		db.getAllProducts().then((r) => {
			setProducts(makeList(r));
		});
	}, []);

	useEffect(() => {
		if (!_.isEmpty(products)) {
			var cL = [...new Set(products.map((p) => p.category))];
			cL = _.filter(cL, function(obj) {
				return _.lowerCase(obj) !== "clothing";
			});
			setCategoryList(cL);
			setLoading(false);
		}
	}, [products]);

	useEffect(() => {
		if (!_.isEmpty(selectedProd)) {
			setValues({
				...values,
				productName: selectedProd.productName,
				id: selectedProd.id,
				img: selectedProd.img,
				metaDesc: selectedProd.metaDesc,
				keywords: selectedProd.keywords,
				dateAdded: selectedProd.dateAdded ? selectedProd.dateAdded : "",
				sellOffers: selectedProd.sellOffers,
				buyOffers: selectedProd.buyOffers,
				category: selectedProd.category,
				entrupy: selectedProd.entrupy ? selectedProd.entrupy : false,
				lastSoldNew: selectedProd.lastSoldNew ? selectedProd.lastSoldNew : "",
				lastSoldUsed: selectedProd.lastSoldUsed ? selectedProd.lastSoldUsed : "",
				rrp: selectedProd.rrp ? selectedProd.rrp : "",
				trending: selectedProd.trending ? selectedProd.trending : false,
				gender: selectedProd.gender ? selectedProd.gender : "",
			});
			setImageUrl(selectedProd.img);
		}
	}, [selectedProd]);

	const handleChangeCheckboxEx = (event) => {
		setExtras({ ...extras, [event.target.name]: event.target.checked });
	};

	async function uploadImageAsPromise(file, i) {
		return new Promise(function(resolve, reject) {
			var x = file.lastModified;
			var y = file.size;

			var image = file;
			var fileType = image.type.slice(image.type.indexOf("/") + 1, image.type.length);

			const uploadTask = userfb
				.storage()
				.ref(`sell/${x}${y}.${fileType}`)
				.put(image);
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					setImageLoading(true);
				},
				(error) => {
					// error function ....
					setImageLoading(false);
					console.log(error);
				},
				() => {
					// complete function ....
					userfb
						.storage()
						.ref(`sell/${x}${y}.${fileType}`)
						.getDownloadURL()
						.then((iUrl) => {
							if (imageUrl === "") setImageUrl(iUrl);
							let arr = images;
							arr.push(iUrl);
							setImages(arr);
							setImageLoading(false);
							resolve(iUrl);
						});
				}
			);
		});
	}

	const handleCapture = () => async (e) => {
		var files = e.target.files;

		for (var i = 0, file; (file = files[i]); i++) {
			await uploadImageAsPromise(file, i).then((res) => {
				console.log("Upload Complete");
			});
		}

		setImageUrl(images[0]);
	};
	const removeImg = (val) => {
		if (images.length === 1) setImageUrl("");
		let arr = images;
		arr = arr.filter((item) => item !== val);
		setImages(arr);
		setImageUrl(arr[0] ? arr[0] : "");
	};

	const handleUrl = async (event) => {
		let url = event.target.value;
		await fetch(`https://us-central1-oaklas-user.cloudfunctions.net/getUrlMeta?url=${url}`)
			.then((response) => response.json())
			.then((data) => {
				let src = data.data.image;
				let name = _.startCase(_.toLower(data.data.title));
				setImageUrl(src);
				let m = `Discover the ${name} with EnterOffer Marketplace`;
				let i = _.kebabCase(name);

				setValues({
					...values,
					url: url,
					productName: name,
					metaDesc: m,
					id: i,
				});
			});
	};

	useEffect(() => {
		let m = `Discover the ${values.productName} with EnterOffer Marketplace`;
		let i = _.kebabCase(values.productName);
		setValues({
			...values,
			metaDesc: m,
			id: i,
		});
	}, [values.productName]);

	const handleChange = (name) => (event) => {
		if (name === "productName") {
			let m = `Discover the ${event.target.value} with EnterOffer Marketplace`;
			let i = _.kebabCase(event.target.value);
			setValues({
				...values,
				productName: event.target.value,
				metaDesc: m,
				id: i,
			});
		} else {
			setValues({
				...values,
				[name]: event.target.value,
			});
		}
	};

	const [type, setType] = React.useState("Sell");

	const handleType = (event) => {
		setType(event.target.value);
	};

	function convertToArray(arr) {
		if (copiedOver) {
			let n = arr;
			n = n.filter(function(el) {
				return el != null;
			});
			return n;
		} else {
			if (!_.isEmpty(arr)) {
				var f = arr.substring(1, arr.length - 1);
				var i = f.replace(/\"/g, "");

				var n = i.split(",");
				console.log(n);
				n = n.filter(function(el) {
					return el != null;
				});
				return n;
			} else return [];
		}
	}

	const submit = (event) => {
		
		let newBuyOfferObj = {
			offer: values.offer,
			condition: values.condition,
			location: values.location,
			imgs: convertToArray(values.imgs),
			videoUrl: values.videoUrl,
			videoPhotoUrl: values.videoPhotoUrl,
			videoDuration: values.videoDuration,
			productDesc: values.productDesc ? values.productDesc : "",
			submittedDate: values.dateAdded, //same as dateAdded
			sellId: values.sellId,
			sellerId: values.sellerId,
			sellerEmail: values.sellerEmail,
			sold: false, // a newly uploaded product is not sold yet!
			comesWith: {
				case: extras.case,
				receipt: extras.receipt,
				packaging: extras.packaging,
				cert: extras.cert,
				dustbag: extras.dustbag,
				tags: extras.tags,
			},
			untilDate: values.untilDate,
			color: values.color ? values.color : [],
			ownedFor: values.ownedFor,
		};

		let newSellOfferObj = {
			offer: values.offer,
			condition: values.condition,
			location: values.location,
			buyId: values.buyId,
		};

		let newProd = {
			productName: values.productName,
			id: String(values.id).toLowerCase(),
			img: imageUrl,
			metaDesc: values.metaDesc,
			keywords: values.keywords,
			dateAdded: values.dateAdded,
			sellOffers: [],
			buyOffers: [],
			category: values.category,
			lastSoldNew: values.lastSoldNew ? values.lastSoldNew : "",
			lastSoldUsed: values.lastSoldUsed ? values.lastSoldUsed : "",
			rrp: values.rrp ? values.rrp : "",
			trending: values.trending,
			brand: values.brand,
			entrupy: values.entrupy,
			gender: values.gender,
		};

		if (!_.isEmpty(selectedProd)) {
			if (type === "Sell") {
				let b = selectedProd.buyOffers ? selectedProd.buyOffers : [];
				b.push(newBuyOfferObj);
				newProd.buyOffers = b;
			} else newProd.sellOffers.push(newSellOfferObj);
		} else {
			if (type === "Sell") {
				newProd.buyOffers = [newBuyOfferObj];
			} else newProd.sellOffers = [newSellOfferObj];
		}

		var urlProductName = values.productName.split(' ').join('-')
		listingApproved(values.sellerEmail, values.dateAdded, urlProductName, values.productName, values.imgs[0],values.sellId).then(()=>{
			db.addProduct(newProd);
			let url = `https://market.enteroffer.com/${values.id}`;
			if (type === "Sell") db.updateListing("sell", values.sellId, url, values.id).then(() => {
				window.location.reload();
			});
			else db.updateListing("buy", values.buyId, url, values.id).then(() => {
				window.location.reload();
			});

		});


	};

	const [openEnlarge, setOpenEnlarge] = useState("");
	const handleEnlarge = (img) => {
		setOpenEnlarge(img);
	};

	const handleCopyOver = (obj, type) => {
		console.log(obj);
		setCopiedOver(true);
		if (type === "sell") {
			setValues({
				...values,
				productName: obj.offer.productName,
				dateAdded: obj.offer.submittedDate,
				offer: obj.offer.offer,
				condition: obj.offer.condition,
				location: obj.offer.location,
				imgs: obj.offer.images,
				videoUrl: obj.offer.videoUrl,
				videoPhotoUrl: obj.offer.videoPhotoUrl,
				videoDuration: obj.offer.videoDuration,
				productDesc: obj.offer.productDesc,
				sellId: obj.sellId,
				sellerId: obj.offer.sellerId,
				sellerEmail: obj.offer.sellerEmail,
				untilDate: obj.offer.untilDate,
				color: obj.offer.color,
				ownedFor: obj.offer.ownedFor,
				brand: obj.offer.brand
			});

			if (obj.offer.extras) setExtras(obj.offer.extras);
			setType("Sell");
		} else {
			setValues({
				...values,
				productName: obj.offer.productName,
				dateAdded: obj.offer.submittedDate,
				offer: obj.offer.offer,
				condition: obj.offer.condition,
				location: obj.offer.location,
				buyId: obj.buyId,
				sellerId: obj.offer.sellerId,
				sellerEmail: obj.offer.sellerEmail,
			});
			setType("Buy");
		}
	};

	const handleCategory = (c) => {
		let n = c.title ? c.title : c;
		setValues({ ...values, category: n });
	};

	return loading ? (
		<div
			style={{
				left: "50%",
				top: "50%",
				marginLeft: "auto",
				marginRight: "auto",
				display: "inline-block",
				height: "100vh",
			}}
		>
			<Lottie
				height={300}
				width={300}
				options={{
					loop: true,
					autoplay: true,
					animationData: loader.default,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice",
					},
				}}
				style={{
					position: "absolute",
					top: "50%",
					transform: "translateY(-50%) translateX(-50%)",
					left: "50%",
				}}
			/>
		</div>
	) : (
		<div className={classes.root}>
			<Dialog open={openEnlarge !== "" ? true : false} onClose={() => setOpenEnlarge("")}>
				<img
					src={openEnlarge}
					alt="Uploaded Product"
					style={{
						display: "inline-block",
						height: props.isMobile ? "100%" : 500,
						width: props.isMobile ? "100%" : 500,
						objectFit: "contain",
					}}
				/>
			</Dialog>
			<Grid container id="MpProductUploader" item spacing={4} xs={12} sm={9} md={6} style={{ margin: "auto", textAlign: "left", marginBottom: 50 }}>
				<Grid
					item
					xs={12}
					style={{
						textAlign: "center",
					}}
				>
					<h1
						style={{
							fontSize: "1.5em",
							fontWeight: 800,
							textAlign: "center",
						}}
					>
						Marketplace Product Uploader
					</h1>
					<p style={{ fontSize: "1em", textAlign: "center" }}>Make sure you check all the details thoroughly before saving.</p>
					<p style={{ fontSize: "0.8em", textAlign: "center" }}>If anything goes wrong just refresh.</p>
					<br />
					{!_.isEmpty(products) ? (
						<Autocomplete
							options={products}
							getOptionLabel={(option) => option.productName}
							onChange={(event, newValue) => {
								setSelectedProd(newValue);
							}}
							style={{ width: "100%" }}
							renderInput={(params) => <TextField {...params} label="Pre-exisiting Products" variant="outlined" />}
						/>
					) : null}
					<br />
					<Divider />
					<br />
				</Grid>
				<Grid item md={6} xs={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
					{imageUrl === "" ? (
						<React.Fragment>
							<input accept="image/*" className={classes.input} id="contained-button-file" multiple onChange={handleCapture()} type="file" />

							<label htmlFor="contained-button-file">
								<div
									onChange={handleCapture()}
									style={{
										border: null,
										boxShadow: null,
										minHeight: "150px",
										display: "flex",
										padding: 4,
									}}
									className={classes.button}
								>
									<div
										style={{
											marginLeft: "auto",
											marginRight: "auto",
											textAlign: "center",
											height: "100%",
										}}
									>
										{imageLoading === true ? (
											<div
												style={{
													left: "50%",
													top: "50%",
													marginLeft: "auto",
													marginRight: "auto",
													display: "inline-block",
												}}
											>
												<Lottie
													height={150}
													width={150}
													options={{
														loop: true,
														autoplay: true,
														animationData: loader.default,
														rendererSettings: {
															preserveAspectRatio: "xMidYMid slice",
														},
													}}
												/>
											</div>
										) : (
											<span style={{ textAlign: "center" }}>
												<ImageIcon style={{ fontSize: "2.4em", marginTop: 28 }} />
												<p style={{ margin: 6, fontSize: "1em" }}>Upload Stock Product Image</p>
											</span>
										)}
									</div>
								</div>
							</label>
						</React.Fragment>
					) : null}
					{imageUrl !== "" ? (
						<div
							style={{
								border: null,
								boxShadow: null,
								minHeight: "150px",
								display: "flex",
								padding: 4,
							}}
							className={classes.button}
						>
							<Badge
								badgeContent={<CloseIcon id="closeBadge" onClick={() => removeImg(imageUrl)} style={{ fontSize: "1.2em" }} />}
								key={`badge-img`}
								color="primary"
							>
								<img
									src={imageUrl}
									onClick={() => handleEnlarge(imageUrl)}
									alt="Uploaded Product"
									style={{
										display: "inline-block",
										height: 140,
										width: "100%",
										objectFit: "contain",
										top: 0,
										transform: "translateY(0%)",
									}}
								/>
							</Badge>
						</div>
					) : null}
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						style={{ marginTop: 16 }}
						value={values.url}
						onChange={handleUrl}
						label="Url to pull data from"
						variant="outlined"
					/>

					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						style={{ marginTop: 16 }}
						value={values.metaDesc}
						onChange={handleChange("metaDesc")}
						label="Meta Description"
						variant="outlined"
					/>
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.keywords}
						onChange={handleChange("keywords")}
						label="Keywords"
						variant="outlined"
					/>
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.brand}
						onChange={handleChange("brand")}
						label="Brand"
						variant="outlined"
					/>
					<FormControl className={classes.formControl}>
						<InputLabel>Entrupy</InputLabel>
						<Select value={values.entrupy} onChange={handleChange("entrupy")}>
							<MenuItem value={true}>True</MenuItem>
							<MenuItem value={false}>False</MenuItem>
						</Select>
					</FormControl>
					<FormControl className={classes.formControl}>
						<InputLabel>Gender</InputLabel>
						<Select value={values.gender} onChange={handleChange("gender")}>
							<MenuItem value={"Men"}>Men</MenuItem>
							<MenuItem value={"Women"}>Women</MenuItem>
							<MenuItem value={"Unisex"}>Unisex</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item md={6} xs={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
					<TextField
						margin="dense"
						className={classes.textField}
						style={{ marginTop: 0 }}
						fullWidth
						value={values.productName}
						onChange={handleChange("productName")}
						label="Product Name"
						variant="outlined"
					/>
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.id}
						onChange={handleChange("id")}
						label="Product ID"
						variant="outlined"
					/>

					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						style={{ marginBottom: 0 }}
						value={values.dateAdded}
						onChange={handleChange("dateAdded")}
						label="Date Added in UNIX"
						variant="outlined"
						type="number"
					/>
					<p style={{ fontSize: "0.7em", margin: 0, marginBottom: 10, textAlign: "center", color: "#929292" }}>
						{values.dateAdded ? moment.unix(values.dateAdded / 1000).format("MMM DD YYYY h:mm:ss a") : "Enter a UNIX value"}
					</p>
					<Categories categoryList={createCL(categoryList)} sendVal={handleCategory} val={values.category} />

					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.rrp}
						onChange={handleChange("rrp")}
						label="RRP"
						variant="outlined"
						type="number"
					/>
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.lastSoldNew}
						onChange={handleChange("lastSoldNew")}
						label="Last Sold New"
						variant="outlined"
						type="number"
					/>
					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.lastSoldUsed}
						onChange={handleChange("lastSoldUsed")}
						label="LastSold Used"
						variant="outlined"
						type="number"
					/>
				</Grid>
				<Grid item md={12} xs={12} style={{ paddingBottom: 0, textAlign: "center" }}>
					<Divider />
					<h3 style={{ fontSize: "1em", textAlign: "left" }}>Enter offer specific details:</h3>
					<FormControl className={classes.formControl} style={{ marginRight: 12 }}>
						<InputLabel>Offer Type</InputLabel>
						<Select value={type} onChange={handleType}>
							<MenuItem value={"Sell"}>Sell</MenuItem>
							<MenuItem value={"Buy"}>Buy</MenuItem>
						</Select>
					</FormControl>

					<FormControl className={classes.formControl} style={{ marginLeft: 12 }}>
						<InputLabel>Condition</InputLabel>
						<Select value={values.condition} onChange={handleChange("condition")}>
							<MenuItem value={"New"}>New</MenuItem>
							<MenuItem value={"Used"}>Used</MenuItem>
						</Select>
					</FormControl>

					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						style={{ marginTop: 16 }}
						value={values.offer}
						onChange={handleChange("offer")}
						label="Offer"
						type="number"
						variant="outlined"
					/>

					<TextField
						margin="dense"
						className={classes.textField}
						fullWidth
						value={values.location}
						onChange={handleChange("location")}
						label="Location"
						variant="outlined"
					/>
					<p style={{ color: "#f00", fontSize: 14, margin: 0 }}>Make sure you simplify address. e.g. Rose Bay, NSW</p>
					{type !== "Sell" ? (
						<TextField
							margin="dense"
							className={classes.textField}
							fullWidth
							value={values.buyId}
							onChange={handleChange("buyId")}
							label="Buy ID"
							variant="outlined"
						/>
					) : (
						<React.Fragment>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								multiline
								value={values.productDesc}
								onChange={handleChange("productDesc")}
								label="Product Description"
								variant="outlined"
							/>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								value={values.sellId}
								onChange={handleChange("sellId")}
								label="Sell ID"
								variant="outlined"
							/>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								value={values.imgs}
								onChange={handleChange("imgs")}
								label="Image Array (comma separated strings)"
								variant="outlined"
							/>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								value={values.videoUrl}
								label="video url (MP4 preferred)"
								variant="outlined"
							/>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								value={values.videoPhotoUrl}
								label="video Thumbnail"
								variant="outlined"
							/>
							<TextField
								margin="dense"
								className={classes.textField}
								fullWidth
								value={values.videoDuration}
								label="video Duration (seconds)"
								variant="outlined"
							/>
							<FormGroup row>
								<FormControlLabel
									control={<Checkbox color="primary" checked={extras.case} onChange={handleChangeCheckboxEx} name="case" />}
									label="Case/Box"
								/>
								<FormControlLabel
									control={<Checkbox color="primary" checked={extras.packaging} onChange={handleChangeCheckboxEx} name="packaging" />}
									label="Packaging"
								/>
								<FormControlLabel
									control={<Checkbox color="primary" checked={extras.receipt} onChange={handleChangeCheckboxEx} name="receipt" />}
									label="Receipt"
								/>

								<FormControlLabel
									control={<Checkbox color="primary" checked={extras.tags} onChange={handleChangeCheckboxEx} name="tags" />}
									label="Tags"
								/>
								<FormControlLabel
									control={<Checkbox color="primary" checked={extras.dustbag} onChange={handleChangeCheckboxEx} name="dustbag" />}
									label="Dust Bag"
								/>
								<FormControlLabel
									id="sellCheck"
									control={<Checkbox color="primary" checked={extras.cert} onChange={handleChangeCheckboxEx} name="cert" />}
									label="Certificate of Authenticity"
								/>
							</FormGroup>
						</React.Fragment>
					)}
					<FormControl className={classes.formControl} style={{ marginLeft: 12 }}>
						<InputLabel>Trending</InputLabel>
						<Select value={values.trending} onChange={handleChange("trending")}>
							<MenuItem value={true}>True</MenuItem>
							<MenuItem value={false}>False</MenuItem>
						</Select>
					</FormControl>
					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={submit}>
						Add to Database
					</Button>
				</Grid>
			</Grid>
			<br />
			<br />
			<Divider />
			<br />
			<br />
			<AllListingsTable copyOver={handleCopyOver} products={products} />
		</div>
	);
}

export default withStyles(styles)(MpProductUploader);

const filter = createFilterOptions();

function createCL(cl) {
	var l = [];
	for (let i in cl) {
		let o = {};
		o["title"] = cl[i];
		l.push(o);
	}
	return l;
}

function Categories(props) {
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (props.val !== "") setValue(props.val);
	}, [props.val]);

	return (
		<Autocomplete
			value={value}
			onChange={(event, newValue) => {
				if (typeof newValue === "string") {
					setValue({
						title: newValue,
					});
					props.sendVal(newValue);
				} else if (newValue && newValue.inputValue) {
					// Create a new value from the user input
					setValue({
						title: newValue.inputValue,
					});
					props.sendVal(newValue.inputValue);
				} else {
					setValue(newValue);
					props.sendVal(newValue);
				}
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				// Suggest the creation of a new value
				if (params.inputValue !== "") {
					filtered.push({
						inputValue: params.inputValue,
						title: `Add "${params.inputValue}"`,
					});
				}

				return filtered;
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			options={props.categoryList}
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === "string") {
					return option;
				}
				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return option.title;
			}}
			renderOption={(option) => option.title}
			style={{ width: "100%" }}
			freeSolo
			renderInput={(params) => <TextField {...params} label="Categories" variant="outlined" />}
		/>
	);
}
