import React, { Component } from "react";
import "./App.css";
import {
	Home,
	Login,
	PageNotFound,
	PageContainer,
	Offers,
	Statistics,
	CustomerData,
	OnboardingTable,
	MpProductUploader,
	FollowingInfo,
	MpProducts,
} from "./pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "typeface-nunito";
import AuthorizedRoute from "./authorizedRoute";

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#484848",
			main: "#35B2FF",
			dark: "#000000",
			contrastText: "#fff",
		},
		fontFamily: "Nunito, sans-serif",
	},
	overrides: {
		MuiDrawer: {
			// Name of the styleSheet
			paper: {
				// Name of the rule
				backgroundColor: "white",
			},
		},
		MuiBottomNavigationAction: {
			label: {
				color: "white",
			},
		},
	},
});

class App extends Component {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Router>
					<div
						style={{
							fontFamily: "Nunito",
							display: "flex",
							flexDirection: "column",
							minHeight: "100vh",
							maxWidth: "100%",
							overflowX: "hidden",
						}}
					>
						<Switch>
							<Route exact path="/login" component={Login} />
							<AuthorizedRoute exact path="/" component={HomeWrapped} />
							<AuthorizedRoute exact path="/offers" component={OffersWrapped} />
							<AuthorizedRoute exact path="/stats" component={StatisticsWrapped} />
							<AuthorizedRoute exact path="/onboarding" component={OnboardingWrapped} />
							<AuthorizedRoute exact path="/custdata" component={CustomerDataWrapped} />
							<AuthorizedRoute exact path="/mp-product-upload" component={MpProductUploadWrapper} />
							<AuthorizedRoute exact path="/following-info" component={FollowingInfoWrapper} />
							<AuthorizedRoute exact path="/mp-products" component={MpProductsWrapper} />
							<Route component={PageNotFound} />
						</Switch>
					</div>
				</Router>
			</MuiThemeProvider>
		);
	}
}

const HomeWrapped = () => <PageContainer page={Home} />;
const OffersWrapped = () => <PageContainer page={Offers} />;
const CustomerDataWrapped = () => <PageContainer page={CustomerData} />;
const StatisticsWrapped = () => <PageContainer page={Statistics} />;
const OnboardingWrapped = () => <PageContainer page={OnboardingTable} />;
const MpProductUploadWrapper = () => <PageContainer page={MpProductUploader} />;
const FollowingInfoWrapper = () => <PageContainer page={FollowingInfo} />;
const MpProductsWrapper = () => <PageContainer page={MpProducts} />;

export default App;
