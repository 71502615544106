import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { db } from "../firebase";
import { Typography, Snackbar } from "@material-ui/core";
import Moment from "moment";

class SalesBarChart extends Component {
	state = {
		approved: {},
		storeName: "",
		value: "",
		open: false,
		range: 6
	};

	static defaultProps = {
		displayTitle: true,
		displayLegend: true,
		legendPosition: "top"
	};

	componentDidMount() {
		let arr = {};
		for (var i = 0; i < this.props.range; i++) {
			arr[
				Moment()
					.subtract(i, "months")
					.month()
			] = 0;
		}
		this.setState({ approved: arr, range: this.props.range });
		this.updateState(arr, this.state.range);
	}

	componentWillReceiveProps(props) {
		this.setState({ range: props.range });
		let arr = {};
		for (var i = 0; i < this.props.range; i++) {
			arr[
				Moment()
					.subtract(i, "months")
					.month()
			] = 0;
		}
		this.updateState(arr, props.range);
	}

	updateState(arr, range) {
		let allstoreval = this.props.offers;
		for (let store in allstoreval) {
			if (store !== "productsRUs" && store !== "bestFruits" && store !== "undefined" && store !== "approved" && store !== "apparelForYou") {
				for (let section in allstoreval[store]) {
					if (section === "approved") {
						for (let key in allstoreval[store][section]) {
							if (allstoreval[store][section][key].payment && allstoreval[store][section][key].order && Moment().diff(allstoreval[store][section][key].submittedDate, "months") < range) {
								var month = Moment(allstoreval[store][section][key].submittedDate).month();
								let bid = Number(allstoreval[store][section][key].bidPrice);
								arr[month] = arr[month] + bid || 0;
							}
						}
					}
				}
			}
			this.setState({ approved: arr });
		}
	}

	handleCloseSnackSuccess = () => {
		this.setState({ open: false });
	};

	render() {
		var chartLabels = [];
		var cD = [];
		for (let i = this.props.range - 1; i >= 0; i--) {
			let lbl = Moment()
				.subtract(i, "months")
				.format("MMM");
			chartLabels.push(lbl);
			cD.push(
				this.state.approved[
					Moment()
						.subtract(i, "months")
						.month()
				]
			);
		}
		var chartData = {
			labels: chartLabels,
			datasets: [
				{
					label: "APPROVED",
					data: cD,
					borderWidth: 1,
					borderColor: "rgba(42, 228, 155, 1)",
					backgroundColor: "rgba(42, 228, 155, .13)",
					fill: false
				}
			]
		};

		return (
			<div
				style={{
					verticalAlign: "middle",
					display: "grid"
				}}>
				<div style={{ display: "inline", textAlign: "left" }}>
					<Typography
						style={{
							color: "#000",
							fontWeight: 700,
							fontSize: "1em"
						}}>
						Sales
					</Typography>
				</div>
				<Bar
					data={chartData}
					options={{
						scales: {
							yAxes: [
								{
									ticks: {
										min: 0,
										callback: function(value) {
											if (value % 1 === 0) {
												return value;
											}
										}
									}
								}
							]
						}
					}}
				/>

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
					open={this.state.open}
					onClose={this.handleCloseSnackSuccess}
					autoHideDuration={3000}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id='message-id'>Graph Updated</span>}
				/>
			</div>
		);
	}
}

export default SalesBarChart;
