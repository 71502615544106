import React, { Component } from "react";
import { Navigation, BottomNav, SideDrawer } from "../components";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import withSizes from "react-sizes";
import { Offline } from "react-detect-offline";
import Snackbar from "@material-ui/core/Snackbar";

class PageContainer extends Component {
	static propTypes = {
		page: PropTypes.func.isRequired
	};
	constructor() {
		super();
		this.state = { isDrawerOpen: false };
	}
	toggleDrawer = () => {
		this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
	};

	render() {
		const { page: Page, isMobile } = this.props;

		return (
			<div>
				<Grid container spacing={0} direction='column'>
					<Grid item>
						<Navigation toggleDrawer={this.toggleDrawer} />
						<SideDrawer drawerStatus={this.state.isDrawerOpen} toggleDrawer={this.toggleDrawer} />
					</Grid>
					<Grid item style={{ backgroundColor: "#fafafa", paddingBottom: isMobile ? "50px" : "100px" }}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								minHeight: "100vh"
							}}>
							<div style={{ flex: 1 }}>
								<Grid container justify='center'>
									<Grid item xs={12} style={{ padding: isMobile ? null : 16 }}>
										<Page />
									</Grid>
								</Grid>
							</div>
						</div>
					</Grid>
					<Grid item>
						<BottomNav />
					</Grid>
				</Grid>

				<Offline>
					<Snackbar
						anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
						open={true}
						ContentProps={{
							"aria-describedby": "message-id"
						}}
						message={<span id='message-id'>You're offline, please reconnect</span>}
					/>
				</Offline>
			</div>
		);
	}
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 480
});

export default withSizes(mapSizesToProps)(PageContainer);
