import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withSizes from "react-sizes";
import { db, fb } from "../firebase";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import "../App.css";
import { CSVLink } from "react-csv";
import ReactExport from "react-export-excel";
import _ from "lodash";

// import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = theme => ({
	root: {
		flexGrow: 1,
		fontFamily: "Nunito",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	button: {
		"&:hover": {
			backgroundColor: "#35b2ff88"
		}
	}
});

class Home extends Component {
	state = {
		userMetaInfo: [],
		userMetaInfoPulled: false,
		allCustomers: {},
		loading: false,
		dataSet2: {}
	};
	componentDidMount() {
		let allcustomers = {};
		this.setState({
			loading: true
		});
		fb.getAllCustomers()
			.then(allcusts => {
				allcusts = allcusts.data;
				allcustomers = allcusts;
				this.setState({
					allCustomers: allcustomers,
					loading: false
				});
			})
			.catch(e => {
				console.log(e);
			});
	}

	getStats = () => {
		// this.setState({ loading: true });
		db.getUserStats().then(results => {
			console.log(results);
			this.setState({ dataSet2: results });
		});
	};

	getUserMetaInfo = () => {
		this.setState({ loading: true });
		db.getUserMetaInfo().then(results => {
			this.setState({ userMetaInfo: results, userMetaInfoPulled: true, loading: false });
		});
	};

	render() {
		const { classes, isMobile, curWidth } = this.props;
		const { dataSet2, allCustomers, loading } = this.state;

		return loading ? (
			<div style={{ textAlign: "center", paddingTop: "100px" }}>
				<CircularProgress size={100} className={classes.progress} />
			</div>
		) : (
			<div style={isMobile ? { paddingTop: "20px" } : { paddingTop: "100px" }} className={classes.root}>
				<Grid container alignItems='center' spacing={4} style={{ textAlign: "left" }}>
					<Grid item xs={12}>
						<Button onClick={this.getStats} variant='contained' color='primary' className={classes.button}>
							Get Stats
						</Button>{" "}
						<Button
							onClick={this.getUserMetaInfo}
							variant='contained'
							color='primary'
							className={classes.button}>
							getUserMetaInfo
						</Button>{" "}
						{this.state.userMetaInfoPulled === true ? (
							<Button variant='contained' color='primary' className={classes.button}>
								<CSVLink data={this.state.userMetaInfo}>Download CSV</CSVLink>
								<GetAppIcon />
							</Button>
						) : null}
					</Grid>
				</Grid>
				{_.isEmpty(dataSet2) ? null : (
					<ExcelFile>
						<ExcelSheet data={dataSet2} name='Customers'>
							<ExcelColumn label='First Name' value='firstName' />
							<ExcelColumn label='Last Name' value='lastName' />
							<ExcelColumn label='UID' value='uid' />
							<ExcelColumn label='Email' value='email' />
							<ExcelColumn label='Conversion Habit' value='conversionHabit' />
							<ExcelColumn label='Offer Habit' value='offerHabit' />
							<ExcelColumn label='Bundle Habit' value='bundleHabit' />
							<ExcelColumn label='Spending Habit' value='spendingHabit' />
							<ExcelColumn label='Num Offers' value='numOffers' />
							<ExcelColumn label='numConverted' value='numConverted' />
							<ExcelColumn label='Avg Bundle Size' value='avgBundleSize' />
							<ExcelColumn label='Avg Spending Price' value='avgSpendingPrice' />
							<ExcelColumn label='Avg Discount Calc' value='avgDiscountCalc' />
							<ExcelColumn label='Avg Discount For Converted' value='avgDiscountForConverted' />
							<ExcelColumn label='Num Stores' value='numStores' />
						</ExcelSheet>
					</ExcelFile>
				)}
				{/* _.map(allCustomers, (x, cnt) => {
					return x.meta &&
						x.meta.recentOfferUrl &&
						x.meta.email &&
						x.meta.recentOfferUrl.indexOf("demo") !== -1 &&
						x.meta.email.indexOf("oaklas.com") === -1 &&
						x.meta.email.indexOf("test.com") === -1 &&
						x.meta.email.indexOf("enteroffer.com") === -1 ? (
						<div key={cnt}>
							<span style={{ color: "red" }}>{x.meta.email}</span>
							<br />
							<p
								style={{
									width: "90vw",
									wordWrap: "break-word"
								}}>
								{x.meta.recentOfferUrl}
							</p>
							<br />
							<br />
						</div>
					) : null;
				}) */}
			</div>
		);
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 480,
	curWidth: width
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Home));
