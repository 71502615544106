import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { auth } from "../firebase";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import HelpOutline from "@material-ui/icons/HelpOutline";
import SignOut from "@material-ui/icons/PowerSettingsNew";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import withSizes from "react-sizes";

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.dark,
		boxShadow: "none",
		flexGrow: 1,
		borderBottom: "1px solid #cccccc",
		background: `#fff`
	},
	AppBar: {
		flexGrow: 1,
		boxShadow: "none",
		// borderBottom: '1px solid grey',
		background: `#fff`,
		padding: theme.spacing(1)
	}
});

class Navigation extends React.Component {
	state = {
		open: false,
		toggleDrawer: false
	};

	handleClickOpenDash = () => {
		this.setState({ open: true, isRefresh: false });
	};

	signOut = () => {
		auth.doSignOut();
	};

	render() {
		const { isMobile, classes } = this.props;

		return (
			<div className={classes.root}>
				<AppBar position='sticky' className={classes.AppBar}>
					<Toolbar>
						<IconButton
							type='button'
							onClick={this.props.toggleDrawer}
							className={classes.menuButton}>
							<MenuIcon />
						</IconButton>
						<Link to='/' style={{ flex: 1 }}>
							<img
								src={
									"https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
								}
								style={
									isMobile
										? { padding: "5px", width: "100px" }
										: { width: "200px" }
								}
								alt=''
							/>
						</Link>

						<Tooltip title='Help'>
							<IconButton
								type='button'
								style={{ color: "#35B2FF" }}
								onClick={this.handleClickOpenDash}>
								<HelpOutline />
							</IconButton>
						</Tooltip>
						<Tooltip title='Sign Out'>
							<IconButton
								type='button'
								style={{ color: "#35B2FF" }}
								onClick={() => this.signOut()}>
								<SignOut />
							</IconButton>
						</Tooltip>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

Navigation.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 480
});

export default withRouter(
	withSizes(mapSizesToProps)(withStyles(styles)(Navigation))
);
