import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.dark,
		boxShadow: "none",
		flexGrow: 1,
		borderBottom: "1px solid grey",
		background: `#fff`,
		display: "flex",
		minHeight: "100vh",
		flexDirection: "column"
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "space-between",
		borderTop: "1px solid #cccccc",
		paddingTop: "16px"
	},
	appBar: {
		top: "auto",
		bottom: 0,
		padding: theme.spacing(1),
		// borderTop: '1px solid grey',
		background: `#fafafa`,
		boxShadow: "none"
	},
	button: {
		color: "#656565"
	},
	logo: {
		width: "150px",
		height: "100%",
		maxWidth: "100%"
	}
});

class BottomNav extends React.Component {
	state = {
		openTerms: false,
		openPrivacy: false
	};

	handleClickOpenTerms = () => {
		this.setState({ openTerms: true });
	};

	handleClickOpenPrivacy = () => {
		this.setState({ openPrivacy: true });
	};

	render() {
		const { classes } = this.props;

		return (
			<AppBar
				position='sticky'
				color='primary'
				className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<div>
						<Button
							color='inherit'
							className={classes.button}
							onClick={this.handleClickOpenTerms}>
							<b style={{ fontWeight: "400" }}> TERMS OF USE </b>
						</Button>
						<Button
							color='inherit'
							className={classes.button}
							onClick={this.handleClickOpenPrivacy}>
							<b style={{ fontWeight: "400" }}>
								{" "}
								PRIVACY POLICY{" "}
							</b>
						</Button>
					</div>
					<div>
						<Typography
							variant='subtitle1'
							className={classes.button}>
							Copyright &copy; {new Date().getFullYear()}{" "}
							EnterOffer
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
		);
	}
}

export default withStyles(styles)(BottomNav);
