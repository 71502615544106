import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { userfb, db, fb } from "../firebase";
import _ from "lodash";

const styles = (theme) => ({
	root: {
		minHeight: "75vh",
		flexGrow: 1,
		fontFamily: theme.palette.fontFamily,
	},

	button: {
		border: "1px solid #e3e3e3",
		borderRadius: "0.6em",
		backgroundColor: "transparent",
		color: "#929292cc",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: 155,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main + "!important",
		},
	},
	input: {
		display: "none",
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "0.9em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "0.9em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: "#e3e3e3",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"&:hover fieldset": {
				border: "1px solid #92929288",
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1em" },
	},
	formControl: {
		margin: "auto",
		minWidth: 200,
	},

	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "1em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
});

function makeList(products) {
	var v = Object.values(products);
	return v;
}

function followList(array, list) {
	let fList = Object.keys(array);
	fList = fList.map((v) => v.toLowerCase());
	var arr = _.filter(list, (obj) => {
		return fList.indexOf(String(obj.productId).toLowerCase()) !== -1;
	});
	let cnt = 0;
	let uid = db.getUID();
	for (let i in arr) {
		if (arr[i].seen[uid]) cnt++;
	}

	return [arr, cnt];
}

const getUserFollowing = async (res) => {
	var allCusts = {};
	await fb
		.getAllCustomers()
		.then((allcusts) => {
			allCusts = allcusts.data;
		})
		.catch((e) => {
			console.log(e);
		});
	var allFollowingCusts = [];
	for (let i in res) {
		let ac = allCusts[`${i}`];
		if (ac) {
			let o = { name: `${ac.meta.firstName} ${ac.meta.lastName}`, email: ac.meta.email, following: res[i] };
			allFollowingCusts.push(o);
		}
	}
	return allFollowingCusts;
};

function FollowingInfo(props) {
	const { classes } = props;

	const [products, setProducts] = useState({});
	const [followList, setFollowList] = useState({});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		db.getAllProducts().then((r) => {
			setProducts(makeList(r));
		});

		db.getAllFollowing()
			.then(async (res) => {
				let followingList = await getUserFollowing(res);
				setFollowList(followingList);
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	return loading ? (
		<div
			style={{
				left: "50%",
				top: "50%",
				marginLeft: "auto",
				marginRight: "auto",
				display: "inline-block",
				height: "100vh",
			}}
		>
			<Lottie
				height={300}
				width={300}
				options={{
					loop: true,
					autoplay: true,
					animationData: loader.default,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice",
					},
				}}
				style={{
					position: "absolute",
					top: "50%",
					transform: "translateY(-50%) translateX(-50%)",
					left: "50%",
				}}
			/>
		</div>
	) : (
		<div className={classes.root}>
			<Grid container id="MpProductUploader" spacing={4} xs={12} md={9} style={{ margin: "auto", textAlign: "left", marginBottom: 50 }}>
				<Grid item xs={12}>
					<h1>Follower Information</h1>
				</Grid>
				{console.log(followList)}
				{followList.map((f, idx) => (
					<React.Fragment key={idx}>
						<Grid item key={idx} xs={4} style={{ paddingTop: 0 }}>
							{f.name} <br />
							{f.email}
						</Grid>
						<Grid item key={idx} xs={8} style={{ paddingTop: 0 }}>
							{Object.keys(f.following).map((i, idxx) => (
								<span key={idxx} style={{ marginTop: 0 }}>
									{_.startCase(_.toLower(i))} ,{" "}
								</span>
							))}
						</Grid>
					</React.Fragment>
				))}
			</Grid>
		</div>
	);
}

export default withStyles(styles)(FollowingInfo);
