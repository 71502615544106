import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { db } from "../firebase";
import moment from "moment";
import _ from "lodash";

function makeRows(arr, type) {
	let ret = [];
	for (let i in arr) {
		let o = arr[i].offer;
		let obj = {};
		obj["productName"] = o.productName;
		obj["condition"] = o.condition;
		obj["location"] = o.location;
		obj["submittedDate"] = o.submittedDate;
		obj["dateAdded"] = o.submittedDate;
		obj["offer"] = o.offer;
		obj["listed"] = o.listed ? o.listed : false;

		if (type === "sell") {
			obj["sellId"] = arr[i].sellId;
			obj["type"] = "sell";
		} else {
			obj["buyId"] = arr[i].buyId;
			obj["type"] = "buy";
		}

		ret.push(obj);
	}

	return ret;
}

function descendingComparator(a, b, orderBy) {
	return a[orderBy] < b[orderBy] ? 1 : -1;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "productName", numeric: false, disablePadding: false, label: "Product Name" },
	{ id: "offer", numeric: false, disablePadding: false, label: "Offer" },
	{ id: "condtion", numeric: false, disablePadding: false, label: "Condition" },
	{ id: "location", numeric: false, disablePadding: false, label: "Location" },
	{ id: "dateAdded", numeric: false, disablePadding: false, label: "Date Added" },
	{ id: "listed", numeric: false, disablePadding: false, label: "Listed" },
];

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ "aria-label": "select all desserts" }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell>{props.id}</TableCell>
				<TableCell padding="checkbox">Copy</TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === "light"
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: "1 1 100%",
	},
}));

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles();
	const { numSelected } = props;

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
					Listings
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton aria-label="delete" onClick={() => props.delete()}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton aria-label="filter list">
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "0.9em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "0.9em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: "#e3e3e3",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"&:hover fieldset": {
				border: "1px solid #92929288",
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1em" },
	},
}));

export default function EnhancedTable(props) {
	const classes = useStyles();
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("calories");
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(20);

	const [type, setType] = useState("sell");
	const [rows, setRows] = useState([]);
	const [buyRows, setBuyRows] = useState([]);
	const [sellRows, setSellRows] = useState([]);

	const [buyListings, setBuyListings] = useState([]);
	const [sellListings, setSellListings] = useState([]);

	const [find, setFind] = useState("");

	useEffect(() => {
		db.getListings().then((r) => {
			setBuyListings(r.buy);
			setSellListings(r.sell);
			setBuyRows(makeRows(r.buy, "buy"));
			setSellRows(makeRows(r.sell, "sell"));
			setRows(makeRows(r.sell, "sell"));
		});
	}, []);

	useEffect(() => {
		if (type === "sell") setRows(sellRows);
		else setRows(buyRows);
	}, [type]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const handleCopy = (event, row) => {
		var res = {};
		if (type === "sell") {
			res = sellListings.find((obj) => {
				return obj.sellId === row.sellId;
			});
		} else {
			res = buyListings.find((obj) => {
				return obj.buyId === row.buyId;
			});
		}
		props.copyOver(res, type);
	};

	const handleDelete = async () => {
		var ids = {};
		for (let i in selected) {
			let r = [];
			if (type === "sell")
				r = sellListings.filter((obj) => {
					return obj.sellId === selected[i];
				});
			else
				r = buyListings.filter((obj) => {
					return obj.buyId === selected[i];
				});
			let id = r[0].offer.listed ? String(r[0].offer.listed).replace("https://market.enteroffer.com/", "") : false;

			ids[`${selected[i]}`] = id ? id.toLowerCase() : false;
		}

		for (let d in ids) {
			var res = props.products.find((obj) => {
				return String(obj.id).toLowerCase() === ids[d];
			});
			var newOfferList = [];
			if (type === "sell" && ids[d]) {
				let newList = res.buyOffers.filter((obj) => {
					return !ids.hasOwnProperty(obj.sellId);
				});
				newOfferList = newList;
			} else if (type === "buy" && ids[d]) {
				let newList = res.sellOffers.filter((obj) => {
					return !ids.hasOwnProperty(obj.buyId);
				});
				newOfferList = newList;
			} else {
				newOfferList = [];
			}

			await db
				.deleteListing(type, d)
				.then((s) => {
					console.log(`Deleted selected id : ${d}`);
				})
				.catch((e) => console.log("error", e));

			let offerType = type === "sell" ? "buyOffers" : "sellOffers";
			if (ids[d])
				await db
					.removeListingFromProduct(ids[d], newOfferList, offerType)
					.then((s) => {
						console.log(`Deleted selected product : ${ids[d]}`);
					})
					.catch((e) => console.log("error", e));
		}

		window.location.reload();
	};

	const handleFind = (e) => {
		setFind(e.target.value);

		var searchPattern = new RegExp(_.lowerCase(e.target.value));

		var newRows = _.filter(rows, function(obj) {
			return searchPattern.test(obj["productName"].toLowerCase()) || searchPattern.test(_.lowerCase(obj["productName"]));
		});

		if (e.target.value === "") {
			if (type === "sell") setRows(sellRows);
			else setRows(buyRows);
		} else setRows(newRows);
	};

	return (
		<div className={classes.root}>
			<Breadcrumbs separator="|">
				<p
					onClick={() => setType("sell")}
					style={{
						color: type === "sell" ? "#000" : null,
						cursor: "pointer",
						fontSize: type === "sell" ? "1.25em" : "0.9em",
						fontWeight: type === "sell" ? 800 : 500,
					}}
				>
					Sell
				</p>
				<p
					onClick={() => setType("buy")}
					style={{
						color: type === "buy" ? "#000" : null,
						cursor: "pointer",
						fontSize: type === "buy" ? "1.25em" : "0.9em",
						fontWeight: type === "buy" ? 800 : 500,
					}}
				>
					Buy
				</p>
			</Breadcrumbs>
			<TextField margin="normal" className={classes.textField} fullWidth value={find} onChange={handleFind} label="Product Name" variant="outlined" />

			<Paper className={classes.paper}>
				<EnhancedTableToolbar numSelected={selected.length} delete={handleDelete} />
				<TableContainer>
					<Table className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							id={type === "sell" ? "Sell ID" : "Buy ID"}
						/>

						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const isItemSelected = isSelected(type === "sell" ? row.sellId : row.buyId);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={type === "sell" ? row.sellId : row.buyId}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													onClick={(event) => handleClick(event, type === "sell" ? row.sellId : row.buyId)}
													checked={isItemSelected}
												/>
											</TableCell>
											<TableCell component="th" id={labelId} scope="row">
												{row.productName}
											</TableCell>
											<TableCell>{row.offer}</TableCell>
											<TableCell>{row.condition}</TableCell>
											<TableCell>{row.location}</TableCell>
											<TableCell>{moment(row.dateAdded).format("MMM DD YYYY h:mm:ss a")}</TableCell>
											<TableCell>
												{row.listed ? (
													<a href={row.listed} target="_blank" rel="noopener noreferrer" style={{ color: "#35b2ff" }}>
														Link
													</a>
												) : (
													"False"
												)}
											</TableCell>
											<TableCell>{type === "sell" ? row.sellId : row.buyId}</TableCell>
											<TableCell id="copy">
												<Button variant="contained" color="primary" onClick={(event) => handleCopy(event, row)}>
													Copy
												</Button>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[20, 50, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
		</div>
	);
}
