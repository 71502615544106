import { adminfb } from "./firebase";
// adminfb.functions().useFunctionsEmulator('http://localhost:9000')
export const getAllCustomers = () => {
	var getAllCustomers = adminfb.functions().httpsCallable("getAllCustomers");
	return getAllCustomers({});
};

export const getAllBids = () => {
	var getAllBids = adminfb.functions().httpsCallable("getAllBids");
	return getAllBids({});
};
