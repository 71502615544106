import React, { Component } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase } from "../firebase";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { OaklasLogoWordWhite } from "../assets";
import * as firebaseui from "firebaseui";

class Login extends Component {
	// The component's Local state.
	state = {
		isSignedIn: false, // Local signed-in state.
		loaded: false
	};

	// Configure FirebaseUI.
	uiConfig = {
		// Popup signin flow rather than redirect flow.
		signInFlow: "popup",
		// We will display Google and Facebook as auth providers.
		credentialHelper: firebaseui.auth.CredentialHelper.NONE,
		signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
		callbacks: {
			// Avoid redirects after sign-in.
			signInSuccessWithAuthResult: () => false
		}
	};

	// Listen to the Firebase Auth state and set the local state.
	componentDidMount() {
		this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user =>
			this.setState({
				isSignedIn: !!user,
				loaded: true
			})
		);
	}

	// Make sure we un-register Firebase observers when the component unmounts.
	componentWillUnmount() {
		this.unregisterAuthObserver();
	}

	render() {
		// if (this.state.loaded && this.state.isSignedIn) {
		//   //redirect to landing
		// }

		if (!this.state.isSignedIn) {
			return (
				<Grid container justify='center' alignItems='center' spacing={0} direction='column'>
					<div
						style={{
							width: "300px",
							background: `linear-gradient(125deg, #35B2FF 50%, #5A89FF 50%)`,
							border: "1px solid #dddddd",
							borderRadius: "5px",
							marginTop: "60px",
							padding: "60px 50px 70px 50px"
						}}>
						<img
							src={OaklasLogoWordWhite}
							style={{
								width: "80%",
								marginTop: "10px",
								marginBottom: "15px",
								display: "block",
								marginLeft: "auto",
								marginRight: "auto"
							}}
							alt=''
						/>
						<h2
							style={{
								color: "white",
								textAlign: "center",
								marginBottom: "70px",
								fontWeight: "300"
							}}>
							Admin Dashboard
						</h2>
						<StyledFirebaseAuth
							uiCallback={ui => ui.disableAutoSignIn()}
							uiConfig={this.uiConfig}
							firebaseAuth={firebase.auth()}
						/>
					</div>
				</Grid>
			);
		} else {
			return (
				<Redirect
					to={{
						pathname: "/stats"
					}}
				/>
			);
		}
	}
}

export default Login;
