import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Switch } from "@material-ui/core";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";
import { db } from "../firebase";
import _ from "lodash";

const styles = (theme) => ({
	root: {
		minHeight: "75vh",
		flexGrow: 1,
		fontFamily: theme.palette.fontFamily,
	},

	button: {
		border: "1px solid #e3e3e3",
		borderRadius: "0.6em",
		backgroundColor: "transparent",
		color: "#929292cc",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: 155,
		boxShadow: "none",
		"&:hover": {
			boxShadow: "none",
			borderColor: theme.palette.primary.main + "!important",
		},
	},
	input: {
		display: "none",
	},
	textField: {
		display: "inline-block",
		width: "100%",

		"& label.Mui-focused": {
			color: theme.palette.primary.main,
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main,
		},
		"& .MuiInputLabel-outlined ": {
			fontSize: "0.9em",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: "0.9em",
			borderRadius: "0.6em",
			"& fieldset": {
				borderColor: "#e3e3e3",
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main,
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main,
			},
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"&:hover fieldset": {
				border: "1px solid #92929288",
			},
		},
		"& .MuiOutlinedInput-input": { padding: "1em" },
	},
	formControl: {
		margin: "auto",
		minWidth: 200,
	},

	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "1em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
});

function makeList(products) {
	var v = Object.values(products);
	return v;
}

function TrendingSwitch(props) {
	const { prod } = props;
	const [t, setT] = useState(prod.trending ? prod.trending : false);
	const handleChange = () => {
		let f = t;
		setT(!f);
		db.makeTrending(prod.id, !f);
	};
	return (
		<p style={{ marginTop: 4 }}>
			<Switch checked={t} onChange={handleChange} color="primary" name="checked" />
			{t ? null : "Not "}Trending
		</p>
	);
}

function MpProducts(props) {
	const { classes } = props;

	const [products, setProducts] = useState({});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		db.getAllProducts().then((r) => {
			setProducts(makeList(r));
			setLoading(false);
		});
	}, []);

	return loading ? (
		<div
			style={{
				left: "50%",
				top: "50%",
				marginLeft: "auto",
				marginRight: "auto",
				display: "inline-block",
				height: "100vh",
			}}
		>
			<Lottie
				height={300}
				width={300}
				options={{
					loop: true,
					autoplay: true,
					animationData: loader.default,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice",
					},
				}}
				style={{
					position: "absolute",
					top: "50%",
					transform: "translateY(-50%) translateX(-50%)",
					left: "50%",
				}}
			/>
		</div>
	) : (
		<div className={classes.root}>
			<Grid container id="MpProductUploader" spacing={4} xs={12} md={9} style={{ margin: "auto", textAlign: "left", marginBottom: 50 }}>
				<Grid item xs={12}>
					<h1>All Products</h1>
				</Grid>
				{products.map((p, idx) => (
					<React.Fragment key={`${idx}`}>
						<Grid item key={`${idx}-1`} xs={2} style={{ paddingTop: 0 }}>
							<img src={p.img} alt={p.name} width={"100%"} height={100} style={{ objectFit: "contain" }} />
						</Grid>
						<Grid item key={`${idx}-2`} xs={4} style={{ paddingTop: 0 }}>
							{p.productName} <br />
							<TrendingSwitch prod={p} />
						</Grid>
					</React.Fragment>
				))}
			</Grid>
		</div>
	);
}

export default withStyles(styles)(MpProducts);
